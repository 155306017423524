import React, { useEffect, useState } from "react";
import { Device } from "../generated/typesQueriesMutations";
import { getToken } from "../App";
import { differenceInMinutes, parseISO } from "date-fns";

export enum ScreenshotMode {
  Output = "output",
  Physical = "physical",
}

async function fetchImage(
  url: string,
  deviceUuid: string,
  mode: ScreenshotMode
): Promise<string> {
  try {
    const token = getToken();

    const res = await fetch(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "device-uuid": deviceUuid,
        mode: mode,
      },
    });

    console.log(res);

    if (!res.ok) {
      throw new Error(`Failed to fetch image, status: ${res.status}`);
    }

    const blob = await res.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
}

export const OutputScreenshotGallery = (props: {
  mode: ScreenshotMode;
  devices: Device[];
}) => {
  const [fetching, setFetching] = useState(false);

  let devices: any[] = [];

  devices = props.devices.filter((device) => {
    if (devices.includes(device.id)) {
      return false;
    } else if (device.lastConnection) {
      const timestampDate = parseISO(device.lastConnection);
      const minutesAgo = differenceInMinutes(new Date(), timestampDate);
      if (minutesAgo > 5) {
        return false;
      } else {
        devices.push(device.id);
        return true;
      }
    } else {
      devices.push(device.id);
      return true;
    }
  });

  if (!devices.length)
    return <div className="mb-6">There are currently no active devices!</div>;

  return (
    <div
      className="columns is-multiline is-centered is-vcentered mb-6"
      style={{ width: "100%" }}
    >
      {devices.map((device) => (
        <OutputScreenshotTile
          key={device.id}
          mode={props.mode}
          device={device}
          fetching={fetching}
          setFetching={setFetching}
        />
      ))}
    </div>
  );
};

export const OutputScreenshotTile = (props: {
  mode: ScreenshotMode;
  device: Device;
  fetching: boolean;
  setFetching: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { device, mode, fetching, setFetching } = props;

  let url: string;

  process.env.NODE_ENV === "development"
    ? (url = `/api/screenshot/${props.mode}/png`)
    : (url = `https://${device.uuid}.viwi.eu/api/screenshot/${mode}/png`);

  const [imgUrl, setImgUrl] = useState(url);
  const [imgSrc, setImgSrc] = useState("");
  const [imgLoading, setImgLoading] = useState(true);
  const [fetchMsg, setFetchMsg] = useState<null | string>(null);

  useEffect(() => {
    if (!fetching) {
      const timeout = setTimeout(() => {
        setImgUrl(url + "?" + Date.now());
        setImgLoading(true);
        setFetching(true);
      }, 15000);
      return () => {
        clearTimeout(timeout);
        setFetching(false);
      };
    }
  }, [url, fetching, setFetching]);

  useEffect(() => {
    if (!fetching) {
      fetchImage(imgUrl, device.uuid, mode)
        .then((blob) => {
          setImgSrc(blob);
        })
        .catch((error: Error) => {
          console.error("Error fetching image:", error);
          setFetchMsg(error.message);
        })
        .finally(() => {
          setImgLoading(false);
          setFetching(false);
        });
    }
  }, [imgUrl, device, mode, fetching, setFetching]);

  return (
    <div className="column is-3 mb-4">
      <div className="columns is-mobile is-vcentered">
        <div className="column is-9">
          <h2 className="title is-6 is-size-7" style={{ minHeight: "24px" }}>
            {props.device.name}
          </h2>
        </div>
        <span className="column is-3">
          {imgLoading ? (
            <progress className="progress is-large is-info" max="100" />
          ) : (
            ""
          )}
        </span>
      </div>
      <figure className="image has-ratio">
        {imgSrc ? (
          <img
            style={{
              background:
                "repeating-linear-gradient(-45deg, #A9A9A9, #A9A9A9 5px, white 5px, white 10px)",
              maxHeight: "25vh",
              width: "auto",
              height: "auto",
              margin: "0 auto",
            }}
            src={imgSrc}
            alt="Screenshot of current program video output"
          />
        ) : (
          <span className="has-text-danger is-size-7">{fetchMsg}</span>
        )}
      </figure>
    </div>
  );
};
