import React from "react";
import Modal from "react-modal";
import { ModalProps } from "../../helpers/interfacesTypesEnums";

const ModalComponent: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  actionButtons,
  isLoading,
  setIsLoading,
  newItemId,
  setNewItemId,
  children,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Modal"
      className={className}
    >
      <div className="is-flex is-justify-content-end">
        <button className="delete" onClick={onRequestClose}></button>
      </div>

      <div
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        style={{
          maxWidth: "1800px",
          width: "100%",
          minHeight: "80%",
          margin: "0 auto",
        }}
      >
        {children}

        <div
          className="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center"
          style={{ gap: ".5rem" }}
        >
          <button
            className="button is-small"
            onClick={() => {
              onRequestClose();
              if (setIsLoading) setIsLoading(false);
              setNewItemId && setNewItemId(null);
            }}
            style={{ fontSize: "14px" }}
          >
            Cancel
          </button>

          {actionButtons?.map((button, index) => (
            <button
              key={index}
              className={`button is-small ${
                setNewItemId ? "is-success" : "bg-danger has-text-white"
              } ${isLoading ? "is-loading" : ""}`}
              onClick={button.onClick}
              disabled={isLoading || (setNewItemId && !newItemId)}
              style={{ fontSize: "14px" }}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
