import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { AllSetListProps } from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  AllDeviceSetsDocument,
} from "../generated/typesQueriesMutations";

import { Spinner } from "./elements/Spinner";

const SearchSets = ({
  newItemId,
  setNewItemId,
  deviceSets,
  subsets,
  setSubsets,
}: AllSetListProps): JSX.Element => {
  // States
  const [allDeviceSets, setAllDeviceSets] = useState<
    GeneratedDeviceSets[] | null
  >(null);

  // Fetch all sets
  const { loading, error } = useQuery(AllDeviceSetsDocument, {
    onCompleted: (data) => {
      // setAllDeviceSets(data.allDeviceSets || []);
      setAllDeviceSets((data.allDeviceSets as GeneratedDeviceSets[]) || null);
    },
  });

  useEffect(() => {
    if (allDeviceSets) {
      allDeviceSets.map((device) => {
        const idExists =
          deviceSets?.some((item: any) => item.id === device.id) ||
          subsets?.some((item: any) => item.id === device.id);

        if (!idExists && setSubsets)
          setSubsets((prevDevices) => {
            if (prevDevices === null) return [device];
            else return [...prevDevices, device];
          });

        return null;
      });
    }
  }, [allDeviceSets, deviceSets, subsets, setSubsets]);

  return (
    <div className="mb-6">
      {loading ? (
        <div className="is-flex is-flex-wrap-wrap is-align-items-flex-start custom-center card-gap">
          <Spinner />
        </div>
      ) : error ? (
        <p className="custom-center">An error occurred while fetching data.</p>
      ) : allDeviceSets && allDeviceSets.length > 0 ? (
        <div>
          <h2 className="title has-text-centered">Choose the Subset</h2>

          <div
            className="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center"
            style={{ maxWidth: "50rem" }}
          >
            {allDeviceSets.map((deviceSet: GeneratedDeviceSets) => (
              <div
                key={deviceSet.id}
                onClick={() => setNewItemId && setNewItemId(deviceSet.id)}
                className={`px-6 py-2 mr-1 border-radius ${
                  newItemId === deviceSet.id && "has-text-white bg-col-green"
                }`}
              >
                <p className="choose-item">{deviceSet.name}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p>No sets found...</p>
        </div>
      )}
    </div>
  );
};

export default SearchSets;
