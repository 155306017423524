import React, { useState } from "react";
import { useMutation, useQuery, MutationFunction } from "@apollo/client";

import {
  SetSettingsProps,
  SettingOptions,
  UpdateSetMutationData,
  UpdateSetMutationVariables,
  AllUsers,
} from "../helpers/interfacesTypesEnums";
import {
  DeviceRole,
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
  UserFromIdDocument,
  AddUserToSetDocument,
  RemoveSetDocument,
  RemoveSetFromChildrenDocument,
  AddDeviceToSetDocument,
  AddSetToSetDocument,
} from "../generated/typesQueriesMutations";

import SettingOption from "./SettingOption";

const SetSettings = ({
  deviceSetId,
  parentDeviceSetId,
  parentDeviceSetName,
  deviceSetName,
  deviceSetRole,
  deviceSets,
  setDeviceSets,
  subsets,
  setSubsets,
  allDevices,
  allSetUsers,
  setAllSetUsers,
  setMessageInfo,
  setShowSettings,
  setAllDeviceUsers,
  setAllDevices,
  allDeviceUsers,
}: SetSettingsProps) => {
  // States
  const [newDeviceId, setNewDeviceId] = useState<number | null>(null);
  const [newUserId, setNewUserId] = useState<number | null>(null);
  const [newSubsetId, setNewSubsetId] = useState<number | null>(null);
  const [role, setRole] = useState<DeviceRole>(DeviceRole.User);

  // Query for UserName
  const [userName, setUserName] = useState<string>("");
  useQuery(UserFromIdDocument, {
    variables: { userId: Number(newUserId) },
    skip: newUserId === null,
    onCompleted: (data) => {
      if (data) setUserName(data?.userFromId.name);
    },
  });

  // Mutations
  const [AddUserToSet] = useMutation(AddUserToSetDocument);
  const [addDeviceToSet] = useMutation(AddDeviceToSetDocument);
  const [AddSetToSet] = useMutation(AddSetToSetDocument);
  const [RemoveSet] = useMutation(RemoveSetDocument);
  const [RemoveSetFromChildren] = useMutation(RemoveSetFromChildrenDocument);

  // State Updaters
  const addUserUpdater = function () {
    // Define new user
    const newUser = {
      userId: newUserId,
      userRole: role,
      userName: userName,
    };

    // Find children and devices
    function findAllIdsByType(
      deviceSets: GeneratedDeviceSets[] | null,
      deviceSetId: number,
      idType: "childSet" | "device"
    ) {
      const deviceSet = deviceSets?.find(
        (deviceSet) => deviceSet.id === deviceSetId
      );

      if (!deviceSet) return [];

      const childSetIds = deviceSet.deviceSets.map((set) => set.id);

      if (idType === "childSet") {
        const nestedChildSetIds: number[] = childSetIds.flatMap(
          (childId: number) => findAllIdsByType(deviceSets, childId, idType)
        );

        return [...childSetIds, ...nestedChildSetIds];
      } else if (idType === "device") {
        const currSetDeviceIds = deviceSet.devices.map((device) => device.id);
        const nestedChildSetDeviceIds: number[] = childSetIds.flatMap(
          (childId: number) => findAllIdsByType(deviceSets, childId, idType)
        );

        return [...new Set([...currSetDeviceIds, ...nestedChildSetDeviceIds])];
      }

      return [];
    }
    // Find child sets
    const childSetIds = findAllIdsByType(deviceSets, deviceSetId, "childSet");
    // Find set devices
    const deviceIds = findAllIdsByType(deviceSets, deviceSetId, "device");

    // Update Set Users
    setAllSetUsers((prevUsers: any) => {
      return prevUsers.map((user: AllUsers) => {
        if (
          user.deviceSetId === deviceSetId ||
          (childSetIds && childSetIds.includes(user.deviceSetId))
        ) {
          const userExists = user.deviceSetUsers.some(
            (u) => u.userId === newUser.userId
          );

          if (!userExists) {
            const updatedDeviceSetUsers = [...user.deviceSetUsers, newUser];

            return { ...user, deviceSetUsers: updatedDeviceSetUsers };
          }
        }

        return user;
      });
    });

    // Update Device Users
    setAllDeviceUsers((prevUsers: any) => {
      // Create a deep copy of the previous users data
      const updatedUsers = JSON.parse(JSON.stringify(prevUsers));

      deviceIds.forEach((deviceid) => {
        updatedUsers.forEach((user: any) => {
          if (
            user.deviceId === deviceid ||
            (deviceIds && deviceIds.includes(user.deviceId))
          ) {
            // Create the new user object based on the provided data
            // const newUser = {
            //   userId: newUserId,
            //   userRole: role,
            //   userName: userName,
            // };

            // Check if the user already exists in deviceUsers
            const userExists = user.deviceUsers.some(
              (u: any) => u.userId === newUser.userId
            );

            if (!userExists) {
              // Add the new user object to deviceUsers
              user.deviceUsers.push(newUser);
            }
          }
        });
      });

      return updatedUsers;
    });
  };

  const addDeviceUpdater = function () {
    // Update Sets and Subsets
    function updateItemSets(itemSet: GeneratedDeviceSets[]) {
      const updatedItems = JSON.parse(JSON.stringify(itemSet));

      const parentItemIndex = updatedItems?.findIndex(
        (itemSet: GeneratedDeviceSets) => itemSet.id === deviceSetId
      );

      if (parentItemIndex !== -1) {
        const device = allDevices?.find(
          (obj: GeneratedDevices | null) => obj?.id === newDeviceId
        );

        updatedItems[parentItemIndex].devices.push(device);
      }

      return updatedItems;
    }

    deviceSets && setDeviceSets(updateItemSets(deviceSets));
    subsets && setSubsets(updateItemSets(subsets));

    setAllDeviceUsers((prevUsers) => {
      const updatedUsers = JSON.parse(JSON.stringify(prevUsers));

      const currSetUsers = allSetUsers.find(
        (set) => set.deviceSetId === deviceSetId
      );

      updatedUsers.forEach((user: any) => {
        if (user.deviceId === newDeviceId) {
          currSetUsers?.deviceSetUsers.forEach((setUser: any) => {
            const userExists = user.deviceUsers.some(
              (u: any) => u.userId === setUser.userId
            );

            if (!userExists) user.deviceUsers.push(setUser);
          });
        }
      });

      return updatedUsers;
    });
  };

  const addSubsetUpdater = function () {
    function updateSets(set: GeneratedDeviceSets[]) {
      const updatedSets = JSON.parse(JSON.stringify(set));

      const parentSetIndex = updatedSets?.findIndex(
        (set: GeneratedDeviceSets) => set.id === deviceSetId
      );

      if (parentSetIndex !== -1) {
        const childSet =
          subsets?.find((set) => set.id === newSubsetId) ||
          deviceSets?.find((set) => set.id === newSubsetId);

        childSet && updatedSets[parentSetIndex].deviceSets.push(childSet);

        // Update children set's users
        const parentUsers = allSetUsers.find(
          (deviceSet) => deviceSet.deviceSetId === deviceSetId
        )?.deviceSetUsers;

        const childUsers = allSetUsers.find(
          (deviceSet) => deviceSet.deviceSetId === newSubsetId
        )?.deviceSetUsers;

        if (parentUsers && childUsers) {
          const updatedChildUsers = [...childUsers];

          parentUsers?.forEach((parentUser) => {
            const userExists = updatedChildUsers.some(
              (childUser) => childUser.userId === parentUser.userId
            );

            if (!userExists) {
              updatedChildUsers.push(parentUser);

              // Update children set device users
              const deviceSet = deviceSets?.find(
                (deviceSet) => deviceSet.id === newSubsetId
              );

              const deviceIds = deviceSet?.devices;

              setAllDeviceUsers((prevUsers) => {
                const updatedUsers = JSON.parse(JSON.stringify(prevUsers));

                deviceIds?.forEach((deviceid) => {
                  updatedUsers.forEach((user: any) => {
                    if (
                      user.deviceId === deviceid.id ||
                      (deviceIds && deviceIds.includes(user.deviceId))
                    ) {
                      const userExists = user.deviceUsers.some(
                        (u: any) => u.userId === parentUser.userId
                      );

                      if (!userExists) user.deviceUsers.push(parentUser);
                    }
                  });
                });

                return updatedUsers;
              });
            }
          });

          if (updatedChildUsers.length > childUsers.length) {
            const updatedAllSetUsers = allSetUsers.map((deviceSet) =>
              deviceSet.deviceSetId === newSubsetId
                ? { ...deviceSet, deviceSetUsers: updatedChildUsers }
                : deviceSet
            );

            setAllSetUsers(updatedAllSetUsers);
          }
        }
      }

      return updatedSets;
    }

    deviceSets && setDeviceSets(updateSets(deviceSets));
    subsets && setSubsets(updateSets(subsets));
  };

  const removeSetUpdater = function () {
    function updateSets(set: GeneratedDeviceSets[]) {
      return set
        .map((setItem) => {
          if (setItem.id === deviceSetId) return null;

          if (setItem.deviceSets && setItem.deviceSets.length > 0) {
            const updatedNestedDeviceSets = setItem.deviceSets.filter(
              (nestedDeviceSet) => nestedDeviceSet.id !== deviceSetId
            );
            return {
              ...setItem,
              deviceSets: updatedNestedDeviceSets,
            };
          }

          return setItem;
        })
        .filter((setItem) => setItem !== null)
        .filter((setItem): setItem is GeneratedDeviceSets => !!setItem);
    }

    deviceSets && setDeviceSets(updateSets(deviceSets));
    subsets && setSubsets(updateSets(subsets));
  };

  const removeSubsetUpdater = function () {
    function updateSets(set: GeneratedDeviceSets[]) {
      return set?.map((deviceSet) => {
        if (
          deviceSet.id === parentDeviceSetId &&
          deviceSet.deviceSets &&
          deviceSet.deviceSets.length > 0
        ) {
          const updatedNestedDeviceSets = deviceSet.deviceSets.filter(
            (nestedDeviceSet) => nestedDeviceSet.id !== deviceSetId
          );

          return {
            ...deviceSet,
            deviceSets: updatedNestedDeviceSets,
          };
        }

        return deviceSet;
      });
    }

    deviceSets && setDeviceSets(updateSets(deviceSets));
    subsets && setSubsets(updateSets(subsets));
  };

  return (
    <div>
      <div className="is-flex is-flex-wrap-wrap gap-04">
        {/* Add Device */}
        <SettingOption
          option={SettingOptions.AddDevice}
          mutation={
            addDeviceToSet as MutationFunction<
              UpdateSetMutationData,
              UpdateSetMutationVariables
            >
          }
          mutationVariables={{
            setEntry: {
              parentDeviceSetId: deviceSetId,
              deviceId: newDeviceId,
            },
          }}
          newItemId={newDeviceId}
          setNewItemId={setNewDeviceId}
          updateState={addDeviceUpdater}
          setMessageInfo={setMessageInfo}
          setShowSettings={setShowSettings}
          allDevices={allDevices}
          setAllDevices={setAllDevices}
          setDeviceSets={setDeviceSets}
          allDeviceUsers={allDeviceUsers}
          setAllDeviceUsers={setAllDeviceUsers}
          setSubsets={setSubsets}
          deviceSets={deviceSets}
          subsets={subsets}
        />
        {/* Add User */}
        <SettingOption
          option={SettingOptions.AddUser}
          mutation={
            AddUserToSet as MutationFunction<
              UpdateSetMutationData,
              UpdateSetMutationVariables
            >
          }
          mutationVariables={{
            userId: newUserId!,
            deviceSet: deviceSetId,
            role: role,
          }}
          newItemId={newUserId}
          setNewItemId={setNewUserId}
          updateState={addUserUpdater}
          role={role}
          setRole={setRole}
          setMessageInfo={setMessageInfo}
          setShowSettings={setShowSettings}
          setAllDevices={setAllDevices}
          setDeviceSets={setDeviceSets}
          allDeviceUsers={allDeviceUsers}
          setAllDeviceUsers={setAllDeviceUsers}
          setSubsets={setSubsets}
          deviceSets={deviceSets}
          subsets={subsets}
        />
        {/* Add Subset */}
        <SettingOption
          option={SettingOptions.AddChild}
          mutation={
            AddSetToSet as MutationFunction<
              UpdateSetMutationData,
              UpdateSetMutationVariables
            >
          }
          mutationVariables={{
            setEntry: {
              parentDeviceSetId: deviceSetId,
              deviceSetId: newSubsetId,
            },
          }}
          newItemId={newSubsetId}
          setNewItemId={setNewSubsetId}
          updateState={addSubsetUpdater}
          setMessageInfo={setMessageInfo}
          setShowSettings={setShowSettings}
          deviceSets={deviceSets}
          subsets={subsets}
          setSubsets={setSubsets}
          setAllDevices={setAllDevices}
          setDeviceSets={setDeviceSets}
          allDeviceUsers={allDeviceUsers}
          setAllDeviceUsers={setAllDeviceUsers}
        />
        {/* Create Subset */}
        <SettingOption
          option={SettingOptions.CreateSubset}
          deviceSetId={deviceSetId}
          setMessageInfo={setMessageInfo}
          setDeviceSets={setDeviceSets}
          setShowSettings={setShowSettings}
          deviceSets={deviceSets}
          subsets={subsets}
          setSubsets={setSubsets}
          setAllDevices={setAllDevices}
          allDeviceUsers={allDeviceUsers}
          setAllDeviceUsers={setAllDeviceUsers}
        />
        {/* Delete Set */}
        <SettingOption
          option={SettingOptions.RemoveSet}
          mutation={
            RemoveSet as MutationFunction<
              UpdateSetMutationData,
              UpdateSetMutationVariables
            >
          }
          mutationVariables={{
            deviceSet: deviceSetId,
          }}
          updateState={removeSetUpdater}
          deviceSetRole={deviceSetRole}
          deviceSetName={deviceSetName}
          setMessageInfo={setMessageInfo}
          setShowSettings={setShowSettings}
          setAllDevices={setAllDevices}
          setDeviceSets={setDeviceSets}
          allDeviceUsers={allDeviceUsers}
          setAllDeviceUsers={setAllDeviceUsers}
          setSubsets={setSubsets}
          deviceSets={deviceSets}
          subsets={subsets}
        />
        {/* Remove Subset */}
        {parentDeviceSetId && (
          <SettingOption
            option={SettingOptions.RemoveChild}
            mutation={
              RemoveSetFromChildren as MutationFunction<
                UpdateSetMutationData,
                UpdateSetMutationVariables
              >
            }
            mutationVariables={{
              parentDeviceSetId: parentDeviceSetId,
              deviceSetId: deviceSetId,
            }}
            updateState={removeSubsetUpdater}
            deviceSetRole={deviceSetRole}
            parentDeviceSetId={parentDeviceSetId}
            deviceSetName={deviceSetName}
            parentDeviceSetName={parentDeviceSetName}
            setMessageInfo={setMessageInfo}
            setShowSettings={setShowSettings}
            setAllDevices={setAllDevices}
            setDeviceSets={setDeviceSets}
            allDeviceUsers={allDeviceUsers}
            setAllDeviceUsers={setAllDeviceUsers}
            setSubsets={setSubsets}
            deviceSets={deviceSets}
            subsets={subsets}
          />
        )}
      </div>
    </div>
  );
};

export default SetSettings;
