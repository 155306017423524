import React, { useState, useEffect } from "react";
import { MessageProps } from "../../helpers/interfacesTypesEnums";

const Message: React.FC<MessageProps> = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [onClose]);

  return isVisible ? (
    <div className={`message ${type} fix-top-right`}>
      <div className="message-header is-flex is-justify-content-flex-end">
        <button className="delete" onClick={() => setIsVisible(false)}></button>
      </div>
      <div className="message-body">{message}</div>
    </div>
  ) : null;
};

export default Message;
