import React, { useState, ChangeEvent } from "react";
import { useQuery } from "@apollo/client";

import {
  SearchUserProps,
  SearchUsersProps,
  UserQueryData,
  UserQueryVariables,
} from "../helpers/interfacesTypesEnums";
import {
  UserFromIdQuery,
  SearchUsersQuery,
  DeviceRole,
  UserFromIdDocument,
  SearchUsersDocument,
} from "../generated/typesQueriesMutations";

import { Spinner } from "./elements/Spinner";
import Input from "./elements/Input";
import RadioButton from "./elements/RadioButton";

const SearchUser = ({
  searchKey,
  selectedOption,
  queryToUse,
  newItemId,
  setNewItemId,
  userData,
  setUserData,
}: SearchUserProps): JSX.Element => {
  // Data Fetching
  const { loading, error } = useQuery<UserQueryData, UserQueryVariables>(
    queryToUse,
    {
      variables:
        selectedOption === "id"
          ? { userId: Number(searchKey) }
          : { query: searchKey },

      onCompleted: (data) => {
        const userData =
          selectedOption === "id"
            ? (data as UserFromIdQuery).userFromId || null
            : (data as SearchUsersQuery).searchUsers?.length !== 0
            ? (data as SearchUsersQuery).searchUsers
            : null;

        setUserData(userData);
      },
    }
  );

  function renderUser(user: { id: number; name: string }) {
    return (
      <div>
        <div
          key={user.id}
          onClick={() => setNewItemId && setNewItemId(user.id)}
          className={`py-2 px-6 border-radius has-text-centered ${
            newItemId === user.id && "bg-col-green has-text-white"
          }`}
        >
          <p className="choose-item">{user.name}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="is-flex is-justify-content-center mb-5 gap-09">
      {loading ? (
        <Spinner />
      ) : error ? (
        <p>No users found with the given ID...</p>
      ) : !userData ? (
        <p>No users found with the given data...</p>
      ) : Array.isArray(userData) ? (
        userData.map((user) => <div key={user.id}>{renderUser(user)}</div>)
      ) : (
        renderUser(userData as { id: number; name: string })
      )}
    </div>
  );
};

const SearchUsers = ({
  newItemId,
  setNewItemId,
  role,
  setRole,
}: SearchUsersProps): JSX.Element => {
  // States
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState("id");
  const [searchKey, setSearchKey] = useState<string>("");

  const [userData, setUserData] = useState<
    Array<{ id: number; name: string }> | { id: number; name: string } | null
  >(null);

  // Inputs
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (searchKey) setSearchKey("");
  };

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue("");
    setSelectedOption(event.target.value);
    if (searchKey) setSearchKey("");
  };

  // Defining the query
  let queryToUse;

  if (selectedOption === "id") {
    queryToUse = UserFromIdDocument;
  } else {
    queryToUse = SearchUsersDocument;
  }

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchKey(inputValue);
    setInputValue("");
  };

  function renderRole() {
    return (
      <div className="field is-flex is-justify-content-center">
        <div className="control">
          <RadioButton
            name="role"
            value="user"
            checked={role === DeviceRole.User}
            onChange={() => setRole!(DeviceRole.User)}
            label="Add as a User"
          />

          <RadioButton
            name="role"
            value="administrator"
            checked={role === DeviceRole.Administrator}
            onChange={() => setRole!(DeviceRole.Administrator)}
            label="Add as an Administrator"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6">
      <h2 className="title has-text-centered">Search Users</h2>

      <div className="is-flex is-align-items-center is-justify-content-center is-align-items-center is-flex-wrap-wrap mb-5 custom-center gap-09">
        <form className="field has-addons mb-0" onSubmit={handleSearch}>
          <div className="control">
            <Input
              type={selectedOption === "id" ? "number" : "text"}
              name="user"
              placeholder={
                selectedOption === "id"
                  ? "Enter user ID"
                  : "Enter username or e-mail"
              }
              value={inputValue || ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event)
              }
              isSmall
            />
          </div>

          <div className="control">
            <button
              className="button is-success is-small"
              disabled={!inputValue}
              aria-label="Search"
            >
              Search
            </button>
          </div>
        </form>

        <div className="field">
          <div className="control has-text-centered">
            <RadioButton
              name="searchOption"
              value="id"
              checked={selectedOption === "id"}
              onChange={handleOptionChange}
              label="Search By ID"
            />

            <RadioButton
              name="searchOption"
              value="name"
              checked={selectedOption === "name"}
              onChange={handleOptionChange}
              label="Search By Name or E-mail"
            />
          </div>
        </div>
      </div>

      {searchKey && queryToUse && (
        <>
          <SearchUser
            searchKey={searchKey}
            selectedOption={selectedOption}
            queryToUse={queryToUse}
            newItemId={newItemId}
            setNewItemId={setNewItemId}
            userData={userData}
            setUserData={setUserData}
          />

          {userData && renderRole()}
        </>
      )}
    </div>
  );
};

export default SearchUsers;
