import React from "react";
import { SetSettingButtonProps } from "../../helpers/interfacesTypesEnums";

const SetSettingButton = ({
  onClick,
  danger,
  children,
}: SetSettingButtonProps) => {
  return (
    <button
      className={`button is-small ${
        danger && "has-text-danger has-text-weight-bold"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SetSettingButton;
