import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { SubsetProps } from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
  DeviceSetDocument,
} from "../generated/typesQueriesMutations";

import Device from "./Device";
import SetUsers from "./SetUsers";
import SetSettings from "./SetSettings";
import IconButton from "./elements/IconButton";
import Button from "./elements/Button";

import { useExpandDevices } from "../hooks/useExpandDevices";

const Subset: React.FC<SubsetProps> = ({
  setEntryId,
  parentDeviceSetId,
  parentDeviceSetName,
  deviceSetRole,
  deviceSets,
  setDeviceSets,
  subsets,
  setSubsets,
  allDevices,
  setAllDevices,
  allSetUsers,
  setAllSetUsers,
  allDeviceUsers,
  setAllDeviceUsers,
  setMessageInfo,
  setScreenshotDevices,
}) => {
  // Retrieve the children data
  const { error, data } = useQuery(DeviceSetDocument, {
    variables: { deviceSet: setEntryId },
  });

  // Show & Hide States
  const [showOptions, setShowOptions] = useState(false);

  const [showDetails, setShowDetails] = useState(() => {
    const storedShowDetails = localStorage.getItem(`${setEntryId}`);
    return storedShowDetails ? storedShowDetails === "true" : false;
  });

  // Expand All Button
  const setDeviceIds = data?.deviceSet.devices.map((device) => device.id);

  const { expandDevices, handleExpandButton, setExpandedDevices } =
    useExpandDevices(setDeviceIds);

  // Updating "subsets" state with children that is not there
  useEffect(() => {
    if (data && data.deviceSet.id) {
      const idExists =
        deviceSets?.some((item) => item.id === data.deviceSet.id) ||
        subsets?.some((item) => item.id === data.deviceSet.id);

      if (!idExists)
        setSubsets((prevDevices) => {
          if (prevDevices === null)
            return [data.deviceSet as GeneratedDeviceSets];
          else return [...prevDevices, data.deviceSet as GeneratedDeviceSets];
        });
    }
  }, [data, deviceSets, subsets, setSubsets]);

  // Rendering children from state
  const childSetEntry =
    deviceSets?.find((item) => item.id === setEntryId) ||
    subsets?.find((item) => item.id === setEntryId);

  const grandChildrenSetEntries =
    deviceSets?.find((item) => item.id === data?.deviceSet.id) ||
    subsets?.find((item) => item.id === data?.deviceSet.id);

  // Local Storage
  useEffect(() => {
    const storedShowDetails = localStorage.getItem(`${setEntryId}`);
    if (storedShowDetails) {
      if (Number(storedShowDetails) === setEntryId) setShowDetails(true);
    }
  }, [setEntryId]);

  useEffect(() => {
    localStorage.setItem(`${setEntryId}`, `${showDetails}`);
  }, [setEntryId, showDetails]);

  useEffect(() => {
    if (showDetails && childSetEntry?.devices) {
      setScreenshotDevices((prev) =>
        prev
          ? [...childSetEntry?.devices, ...prev]
          : [...childSetEntry?.devices]
      );
    }
  }, [showDetails, childSetEntry?.devices, setScreenshotDevices]);

  if (!childSetEntry) return null;

  return (
    <div className={`${showDetails ? "card" : ""}`} key={childSetEntry.id}>
      {/* Card Header */}
      <div
        className={`has-background-dark is-flex is-justify-content-space-between ${
          !showDetails
            ? "border-radius-top border-radius-bottom"
            : "card-header"
        }`}
      >
        <div className="is-flex is-flex-wrap-wrap is-align-items-center pl-4">
          <h3 className="subtitle card-header-title has-text-light p-0 m-0 mr-2">
            {childSetEntry.name}
          </h3>
          <span className="is-size-7 has-text-light py-1">
            [{childSetEntry.myRole}]
          </span>
        </div>

        <div className="is-flex">
          {showDetails && (
            <IconButton
              className="card-header-icon has-text-light py-1"
              onClick={() => setShowOptions((o) => !o)}
              iconName={`${showOptions ? "chevron up" : "chevron down"}`}
              iconColor="white"
            />
          )}

          <IconButton
            className="card-header-icon has-text-light py-1"
            onClick={() => {
              setShowDetails((d) => !d);
              if (showDetails) {
                setScreenshotDevices((prevDevices) => {
                  if (!prevDevices) return [];
                  let removed = false;
                  return prevDevices.filter((prevDevice) => {
                    if (
                      !removed &&
                      childSetEntry.devices.some(
                        (device) => device.id === prevDevice.id
                      )
                    ) {
                      removed = true;
                      return false;
                    }
                    return true;
                  });
                });
              }
            }}
            iconName={showDetails ? "collapse" : "expand"}
            iconColor="white"
          />
        </div>
      </div>

      {/* Card Content */}
      {showDetails && (
        <div className="card-content px-0 py-0">
          <div className="content px-0 py-0">
            {/* Options */}
            {showOptions && (
              <div className={`border has-background-light mb-6 py-3 px-4`}>
                <div className="is-flex is-flex-wrap-wrap gap-04 mb-2">
                  <Button
                    text={
                      expandDevices
                        ? "Collapse All Devices"
                        : "Expand All Devices"
                    }
                    className="button is-small"
                    onClick={handleExpandButton}
                  />

                  <SetSettings
                    deviceSetId={childSetEntry.id}
                    deviceSetRole={childSetEntry.myRole}
                    allDevices={allDevices}
                    deviceSets={deviceSets}
                    setDeviceSets={setDeviceSets}
                    subsets={subsets}
                    setSubsets={setSubsets}
                    deviceSetName={childSetEntry.name}
                    allSetUsers={allSetUsers}
                    setAllSetUsers={setAllSetUsers}
                    setAllDeviceUsers={setAllDeviceUsers}
                    setMessageInfo={setMessageInfo}
                    setShowSettings={setShowOptions}
                    setAllDevices={setAllDevices}
                    allDeviceUsers={allDeviceUsers}
                    parentDeviceSetId={parentDeviceSetId}
                    parentDeviceSetName={parentDeviceSetName}
                  />
                </div>

                <div>
                  <div className="py-2">
                    <span className="mr-1">
                      <i className="fa-solid fa-people-arrows"></i>
                    </span>
                    <span className="mr-1">Users</span>
                  </div>

                  <div>
                    <SetUsers
                      deviceSetId={childSetEntry.id}
                      deviceSetName={childSetEntry.name}
                      deviceSetRole={childSetEntry.myRole}
                      allSetUsers={allSetUsers}
                      setAllSetUsers={setAllSetUsers}
                      deviceSets={deviceSets}
                      setAllDeviceUsers={setAllDeviceUsers}
                      setMessageInfo={setMessageInfo}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Main Content */}
            <div className="p-4">
              <div className="pb-4">
                {childSetEntry.devices && childSetEntry.devices.length > 0 ? (
                  <ul className="columns is-multiline mb-6">
                    {childSetEntry.devices.map((device: GeneratedDevices) => (
                      <li className="column is-half p-1" key={device.id}>
                        <Device
                          device={device}
                          expandDevices={expandDevices}
                          setExpandedDevices={setExpandedDevices}
                          deviceSetId={setEntryId}
                          deviceSets={deviceSets}
                          setDeviceSets={setDeviceSets}
                          subsets={subsets}
                          setSubsets={setSubsets}
                          allDevices={allDevices}
                          setAllDevices={setAllDevices}
                          setMessageInfo={setMessageInfo}
                          allDeviceUsers={allDeviceUsers}
                          setAllDeviceUsers={setAllDeviceUsers}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No devices found...</p>
                )}
              </div>

              <div>
                {error ? (
                  <p>Error fetching data</p>
                ) : childSetEntry && childSetEntry.deviceSets?.length > 0 ? (
                  <ul>
                    {grandChildrenSetEntries?.deviceSets.map(
                      (grandChildSet: GeneratedDeviceSets) => (
                        <li key={grandChildSet.id}>
                          <Subset
                            setEntryId={grandChildSet.id}
                            parentDeviceSetId={data?.deviceSet.id ?? 0}
                            parentDeviceSetName={data?.deviceSet.name ?? ""}
                            deviceSetRole={deviceSetRole}
                            deviceSets={deviceSets}
                            setDeviceSets={setDeviceSets}
                            subsets={subsets}
                            setSubsets={setSubsets}
                            allDevices={allDevices}
                            setAllDevices={setAllDevices}
                            allSetUsers={allSetUsers}
                            setAllSetUsers={setAllSetUsers}
                            setMessageInfo={setMessageInfo}
                            allDeviceUsers={allDeviceUsers}
                            setAllDeviceUsers={setAllDeviceUsers}
                            setScreenshotDevices={setScreenshotDevices}
                          />
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <p>No Subsets found...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subset;
