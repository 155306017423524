import React, { useState, useEffect, PropsWithChildren, FC } from "react";
import {
  OidcSecure,
  OidcProvider,
  OidcConfiguration,
} from "@axa-fr/react-oidc";
import { GQL_ENDPOINT } from "./App";

export const OidcSecureWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isProtected, setIsProtected] = useState(false);
  const [configuration, setConfiguration] = useState<
    OidcConfiguration | undefined
  >(undefined);

  useEffect(() => {
    const baseURL =
      window.location.protocol === "https:"
        ? "https://authentik.videowindow.io/application/o/vw"
        : "http://localhost:9000/application/o/vw";

    fetch(GQL_ENDPOINT, { method: "POST" }).then((response) => {
      // const oauth_url = response.headers.get("OAuth-URL");

      // if (!oauth_url) return;

      setConfiguration({
        client_id: "6b6a3d2c692290ce01e9f0adc5a8b0604afbd374",
        redirect_uri: `${window.location.protocol}//${window.location.host}/control/authentication/callback`,
        scope: "openid profile email id offline_access",
        authority: baseURL,
        // authority: oauth_url,
        service_worker_only: false,
      });

      if (response.status === 400) {
        setIsProtected(false);
      } else if (response.status === 401) {
        setIsProtected(true);
      } else {
        console.log(
          "Unexpected response status when checking auth {}",
          response.status
        );
      }
    });
  }, []);

  if (!isProtected) return null;

  return (
    <OidcProvider configuration={configuration}>
      <OidcSecure> {children}</OidcSecure>
    </OidcProvider>
  );
};
