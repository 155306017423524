import React from "react";

const Footer = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <section className="section is-flex is-justify-content-flex-end pb-2 has-text-dark">
      <div className="has-text-centered">
        Copyright &copy; {currentYear}{" "}
        <span className="has-text-weight-bold">VideowindoW</span>. All rights
        reserved.
      </div>
    </section>
  );
};

export default Footer;
