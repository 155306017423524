import React from "react";
import { UserProps } from "../helpers/interfacesTypesEnums";

const User: React.FC<UserProps> = ({ name, role }) => (
  <div className="user-details">
    <p className="has-text-weight-bold has-text-dark">
      {name} <span className="is-size-7 has-text-black">[{role}]</span>
    </p>
    {/* <p className="is-size-7 has-text-black mb-0">{role}</p> */}
  </div>
);

export default User;
