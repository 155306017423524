import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import {
  AllUsers,
  AllDeviceUsers,
  MessageInfo,
} from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
  UserDeviceSetsDocument,
  AllDevicesDocument,
} from "../generated/typesQueriesMutations";

import CreateSet from "./CreateSet";
import DeviceSet from "./Set";
import { SpinnerCard } from "./elements/Spinner";
import Message from "./elements/Message";
import IconButton from "./elements/IconButton";
import Button from "./elements/Button";
import ModalComponent from "./elements/Modal";
import { OutputScreenshotGallery, ScreenshotMode } from "./OutputScreenshot";

const DeviceSetList = (): JSX.Element => {
  // States
  const [deviceSets, setDeviceSets] = useState<GeneratedDeviceSets[] | null>(
    null
  );
  const [subsets, setSubsets] = useState<GeneratedDeviceSets[] | null>(null);
  const [allSetUsers, setAllSetUsers] = useState<AllUsers[]>([]);
  const [allDeviceUsers, setAllDeviceUsers] = useState<AllDeviceUsers[]>([]);

  // Success/Error message state
  const [messageInfo, setMessageInfo] = useState<MessageInfo>({
    message: null,
    messageType: "is-success",
  });

  // Show/Hide UI state
  const [showInput, setShowInput] = useState(false);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch all sets
  const { loading, error } = useQuery(UserDeviceSetsDocument, {
    onCompleted: (data) => {
      setDeviceSets((data.userDeviceSets as GeneratedDeviceSets[]) || null);
    },
  });

  // Retrieve all devices data
  const [allDevices, setAllDevices] = useState<GeneratedDevices[] | null>(null);

  useQuery(AllDevicesDocument, {
    onCompleted: (data) => {
      setAllDevices(data.allDevices || []);
    },
  });

  const [screenshotDevices, setScreenshotDevices] = useState<
    GeneratedDevices[] | null
  >(null);

  return (
    <section className="section mt-5" style={{ overflowX: "scroll" }}>
      <div className="is-flex-tablet is-justify-content-space-between mb-2">
        <div className="is-flex is-flex-wrap-wrap is-align-items-center custom-center gap-09 mb-3">
          <h2 className="title mb-0">Device Sets</h2>
          {!showInput ? (
            <IconButton
              className="button px-5 border-radius"
              onClick={() => setShowInput(true)}
              iconName="show input"
            />
          ) : (
            <CreateSet
              setMessageInfo={setMessageInfo}
              setDeviceSets={setDeviceSets}
              setShowInput={setShowInput}
              isSmall={false}
            />
          )}
        </div>

        <div className="is-flex custom-center mb-3">
          <Button
            text={`Screenshots`}
            className="button is-default mb-4 has-text-weight-bold"
            onClick={() => setIsModalOpen(true)}
            disabled={screenshotDevices?.length ? false : true}
          >
            <svg
              className="mr-2"
              height="2rem"
              width="2rem"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M430.4,147h-67.5l-40.4-40.8c0,0-0.2-0.2-0.3-0.2l-0.2-0.2v0c-6-6-14.1-9.8-23.3-9.8h-84c-9.8,0-18.5,4.2-24.6,10.9l0,0.1   l-39.5,40H81.6C63,147,48,161.6,48,180.2v202.1c0,18.6,15,33.7,33.6,33.7h348.8c18.5,0,33.6-15.1,33.6-33.7V180.2   C464,161.6,448.9,147,430.4,147z M256,365.5c-50.9,0-92.4-41.6-92.4-92.6c0-51.1,41.5-92.6,92.4-92.6c51,0,92.4,41.5,92.4,92.6   C348.4,323.9,307,365.5,256,365.5z M424.1,200.5c-7.7,0-14-6.3-14-14.1s6.3-14.1,14-14.1c7.7,0,14,6.3,14,14.1   S431.8,200.5,424.1,200.5z" />
                <path d="M256,202.9c-38.6,0-69.8,31.3-69.8,70c0,38.6,31.2,70,69.8,70c38.5,0,69.8-31.3,69.8-70C325.8,234.2,294.5,202.9,256,202.9   z" />
              </g>
            </svg>
            {/* <svg
              className="mr-2"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 11c0-.959-.68-1.761-1.581-1.954C16.779 8.445 17 7.75 17 7c0-2.206-1.794-4-4-4-1.516 0-2.822.857-3.5 2.104C8.822 3.857 7.516 3 6 3 3.794 3 2 4.794 2 7c0 .902.312 1.726.817 2.396A1.993 1.993 0 0 0 2 11v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-2.637l4 2v-7l-4 2V11zm-5-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zM6 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" />
            </svg> */}
            {/* <svg
              enable-background="new 0 0 24 24"
              className="mr-2"
              height="24px"
              width="24px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M22.506,21v0.016L17,15.511V19c0,1.105-0.896,2-2,2h-1.5H3H2c-1.104,0-2-0.895-2-2  v-1l0,0V6l0,0V5c0-1.104,0.896-1.999,2-1.999h1l0,0h10.5l0,0H15c1.104,0,2,0.895,2,1.999v3.516l5.5-5.5V3.001  c0.828,0,1.5,0.671,1.5,1.499v15C24,20.327,23.331,20.996,22.506,21z"
                fill-rule="evenodd"
              />
            </svg> */}
          </Button>
        </div>
      </div>

      {loading ? (
        <div>
          <SpinnerCard />
        </div>
      ) : error ? (
        <p className="custom-center">An error occurred while fetching data.</p>
      ) : deviceSets && deviceSets.length > 0 ? (
        <ul className="custom-center">
          {deviceSets.map((deviceSet: GeneratedDeviceSets) => (
            <DeviceSet
              key={deviceSet.id}
              deviceSet={deviceSet}
              deviceSets={deviceSets}
              setDeviceSets={setDeviceSets}
              allDevices={allDevices}
              setAllDevices={setAllDevices}
              allSetUsers={allSetUsers}
              setAllSetUsers={setAllSetUsers}
              allDeviceUsers={allDeviceUsers}
              setAllDeviceUsers={setAllDeviceUsers}
              setMessageInfo={setMessageInfo}
              subsets={subsets}
              setSubsets={setSubsets}
              setScreenshotDevices={setScreenshotDevices}
            />
          ))}
        </ul>
      ) : (
        <p className="custom-center">No Devices yet...</p>
      )}

      {/* Message Modal */}
      {messageInfo.message && (
        <Message
          message={messageInfo.message}
          type={messageInfo.messageType}
          onClose={() => {
            setMessageInfo({ message: null, messageType: "is-success" });
          }}
        />
      )}

      {/* Modal */}
      {screenshotDevices && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          <OutputScreenshotGallery
            mode={ScreenshotMode.Physical}
            devices={screenshotDevices}
          />
        </ModalComponent>
      )}
    </section>
  );
};

export default DeviceSetList;
