import React, { useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  EditOptionProps,
  EditModeProperty,
} from "../helpers/interfacesTypesEnums";
import Input from "./elements/Input";
import Button from "./elements/Button";
import IconButton from "./elements/IconButton";

const EditOption = ({
  item,
  property,
  targetVariable,
  setMessageInfo,
  mutation,
  updateState,
}: EditOptionProps) => {
  // States
  const [activeEditMode, setActiveEditMode] = useState<EditModeProperty | null>(
    null
  );
  const [inputValue, setInputValue] = useState<string | number>(
    item[property as keyof typeof item] || ""
  );

  // Load state
  const [isUpdating, setIsUpdating] = useState(false);

  // Handle edit button
  const handleEditClick = () => {
    setActiveEditMode(property);
    setInputValue(item[property as keyof typeof item] || "");
  };

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(
      property === EditModeProperty.Gateway
        ? Number(event.target.value)
        : event.target.value
    );
  };

  // Handle update button
  const handleUpdateClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      inputValue === null ||
      inputValue === item[property as keyof typeof item]
    ) {
      setActiveEditMode(null);
      return;
    }

    try {
      setIsUpdating(true);

      const updatedItem = { ...item, [property]: inputValue };

      await mutation({
        variables: {
          [targetVariable]: updatedItem.id,
          [property]: inputValue,
        },
      });

      updateState(updatedItem, inputValue, property);

      setActiveEditMode(null);

      setMessageInfo({
        message: `${
          targetVariable.charAt(0).toUpperCase() +
          targetVariable.slice(1).toLowerCase()
        } ${property} updated successfully.`,
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: `Error updating ${
              targetVariable.charAt(0).toUpperCase() +
              targetVariable.slice(1).toLowerCase()
            } ${property}: ${error.message}`,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message: `An unknown error occurred while updating the ${
              targetVariable.charAt(0).toUpperCase() +
              targetVariable.slice(1).toLowerCase()
            } ${property}. ${error}`,
            messageType: "is-danger",
          });
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="is-flex is-align-items-center" key={item.id}>
      {/* Property */}
      <p className={`mb-0 ${property !== EditModeProperty.Name && "mr-2"}`}>
        {property === EditModeProperty.Name
          ? ""
          : `${property[0].toUpperCase()}${property.slice(1)}: `}
      </p>
      {activeEditMode === property ? (
        <form className="field has-addons" onSubmit={handleUpdateClick}>
          <div className="control">
            <Input
              type={property === EditModeProperty.Gateway ? "number" : "text"}
              name={property}
              placeholder={`Enter new ${property}`}
              value={inputValue.toString()}
              onChange={handleInputChange}
              isSmall
            />
          </div>
          <div className="control">
            <Button
              className="button is-success is-small"
              disabled={inputValue === ""}
              isLoading={isUpdating}
              text={"Update"}
            />
          </div>
          <div className="control">
            <Button
              className={"button is-small"}
              onClick={() => setActiveEditMode(null)}
              text={"Close"}
            />
          </div>
        </form>
      ) : (
        <p
          className={`mb-0 pl-0 mr-1 ${
            property === EditModeProperty.Name && targetVariable === "gateway"
              ? "is-size-5 has-text-light has-text-weight-bold"
              : "is-size-6"
          } ${
            property === EditModeProperty.Name &&
            targetVariable === "device" &&
            "has-text-black has-text-weight-bold"
          }`}
        >
          {item[property as keyof typeof item] || "N/A"}
        </p>
      )}
      {!activeEditMode && (
        <IconButton
          className={`card-header-icon p-0`}
          onClick={handleEditClick}
          iconColor={
            property === EditModeProperty.Name && targetVariable === "gateway"
              ? "has-text-light"
              : property === EditModeProperty.Name &&
                targetVariable === "device"
              ? "has-text-dark"
              : "text-col-dark-grey"
          }
          iconName="edit"
        />
      )}
    </div>
  );
};

export default EditOption;
