import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useMutation, useQuery, ApolloError } from "@apollo/client";

import {
  DeviceProps,
  EditModeProperty,
  EditModeTarget,
  AllDeviceUsers,
} from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
  DeviceRole,
  AllDeviceUsersDocument,
  UpdateDeviceNameDocument,
  UpdateDeviceDomainDocument,
  UpdateDeviceGatewayDocument,
  RemoveDeviceFromSetDocument,
} from "../generated/typesQueriesMutations";
import { differenceInMinutes, formatDistanceToNow, parseISO } from "date-fns";
import EditableComponent from "./EditOption";
import ModalComponent from "./elements/Modal";
import { SpinnerDark } from "./elements/Spinner";
import User from "./User";
import IconButton from "./elements/IconButton";
import Button from "./elements/Button";

const Device = ({
  device,
  allDevices,
  setAllDevices,
  deviceSets,
  setDeviceSets,
  deviceSetId,
  subsets,
  setSubsets,
  allDeviceUsers,
  setAllDeviceUsers,
  expandDevices,
  setExpandedDevices,
  setMessageInfo,
  newItemId,
  setNewItemId,
}: DeviceProps) => {
  // Show & Hide
  const [showDetails, setShowDetails] = useState(false);

  function handleDetailsButton() {
    setShowDetails(!showDetails);

    if (!setExpandedDevices) return;

    showDetails
      ? setExpandedDevices((prevExpandedDevices) =>
          prevExpandedDevices.filter((id) => id !== device.id)
        )
      : setExpandedDevices((prevDev) => [...prevDev, device.id]);
  }

  useEffect(() => {
    expandDevices ? setShowDetails(true) : setShowDetails(false);
  }, [expandDevices]);

  // States
  const [currDeviceUsers, setCurrDeviceUsers] = useState<
    Array<{ userId: number; userRole: DeviceRole; userName: string }>
  >([]);

  // Manage the state of the confirmation modal
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const modalActionButtons = [
    { text: "Remove", onClick: () => confirmRemove() },
  ];

  // Load state
  const [isDeleting, setIsDeleting] = useState(false);

  // Fetch users
  const { loading: loadingUsers, error: errorUsers } = useQuery(
    AllDeviceUsersDocument,
    {
      variables: { device: device.id },
      onCompleted: (data) => {
        if (!setAllDeviceUsers) return;

        setAllDeviceUsers((prevAllDeviceUsers: AllDeviceUsers[]) => {
          // Check if the device already exists in the array
          const deviceExists = prevAllDeviceUsers.some(
            (allDeviceUser) => allDeviceUser.deviceId === device.id
          );

          if (data && !deviceExists) {
            const newData = {
              deviceId: device.id,
              deviceUsers: data.allDeviceUsers.map((allDeviceUser) => ({
                userId: allDeviceUser.user.id,
                userRole: allDeviceUser.role,
                userName: allDeviceUser.user.details.name,
              })),
            };

            // Add newData only if the device doesn't exist in the array
            return [...prevAllDeviceUsers, newData];
          }

          // Return the previous state if no changes are needed
          return prevAllDeviceUsers;
        });
      },
    }
  );

  // Updating "currSetUsers" state
  useEffect(() => {
    const foundDeviceUser = allDeviceUsers?.find(
      (obj: AllDeviceUsers) => obj.deviceId === device.id
    );

    foundDeviceUser
      ? setCurrDeviceUsers(foundDeviceUser?.deviceUsers)
      : setCurrDeviceUsers([]);
  }, [allDeviceUsers, device.id]);

  // Mutations
  const [updateDeviceName] = useMutation(UpdateDeviceNameDocument);
  const [updateDeviceDomain] = useMutation(UpdateDeviceDomainDocument);
  const [updateDeviceGateway] = useMutation(UpdateDeviceGatewayDocument);

  // State updater function
  const stateUpdaterFn = function (
    updatedItem: GeneratedDevices,
    inputValue: number | string,
    property: string
  ) {
    // Update Sets and Subsets
    const updateItems = function (allItems: GeneratedDeviceSets[] | null) {
      return allItems?.map((item) => {
        const deviceIndex = item.devices.findIndex(
          (device: GeneratedDevices) => device.id === updatedItem.id
        );

        if (deviceIndex !== -1)
          return {
            ...item,
            devices: [
              ...item.devices.slice(0, deviceIndex),
              {
                ...item.devices[deviceIndex],
                [property]: inputValue,
              },
              ...item.devices.slice(deviceIndex + 1),
            ],
          };

        return item;
      });
    };

    const updatedAllSets = updateItems(deviceSets);
    setDeviceSets(updatedAllSets || deviceSets);

    const updatedAllSubsets = updateItems(subsets);
    setSubsets(updatedAllSubsets || subsets);

    // Update allDevices
    const updatedDeviceItems = allDevices?.map((item) => {
      if (item.id === updatedItem.id) return updatedItem;

      return item;
    });

    updatedDeviceItems &&
      setAllDevices((prevItems) =>
        prevItems ? updatedDeviceItems : prevItems
      );
  };

  // Handle removing a device
  const [removeSetEntry] = useMutation(RemoveDeviceFromSetDocument);

  const handleRemoveClick = () => {
    setConfirmModalOpen(true);
  };

  const confirmRemove = async () => {
    try {
      setIsDeleting(true);

      if (!deviceSetId) return;

      await removeSetEntry({
        variables: {
          parentDeviceSetId: deviceSetId,
          deviceId: device.id,
        },
      });

      // State update
      const updateDevicesInSet = (
        sets: GeneratedDeviceSets[] | null,
        setFunction: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>,
        setToUpdateId: number,
        deviceId: number
      ) => {
        const updatedSets = sets?.map((set) => {
          if (set.id === setToUpdateId) {
            const filteredDevices = set.devices.filter(
              (device) => device.id !== deviceId
            );
            return { ...set, devices: filteredDevices };
          } else {
            return set;
          }
        });
        updatedSets && setFunction(updatedSets);
      };
      updateDevicesInSet(deviceSets, setDeviceSets, deviceSetId, device.id);
      updateDevicesInSet(subsets, setSubsets, deviceSetId, device.id);

      setMessageInfo({
        message: "Device removed from the set successfully.",
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: "Error removing the device from the set: " + error.message,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message:
              "An unknown error occurred while removing the device from the set." +
              error,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      setIsDeleting(false);
      setConfirmModalOpen(false);
    }
  };

  // Handle rendering the last connection
  const [isLongAgo, setIsLongAgo] = useState(false);

  useEffect(() => {
    if (device.lastConnection) {
      const timestampDate = parseISO(device.lastConnection.toLocaleString());
      const minutesAgo = differenceInMinutes(new Date(), timestampDate);
      if (minutesAgo > 5) setIsLongAgo(true);
    }
  }, [device.lastConnection]);

  const calculateTimeAgo = (apiTimestamp: string): string => {
    return formatDistanceToNow(parseISO(apiTimestamp), { addSuffix: true });
  };

  return (
    <div className="full-width">
      <div
        className={`${setNewItemId && "card"} 
        ${newItemId === device.id && "border-left"}
        `}
      >
        {/* Top */}
        <div
          className={`is-flex is-align-items-center ${
            isLongAgo && !setNewItemId
              ? "bg-col-yellow"
              : !setNewItemId
              ? "bg-col-main"
              : ""
          } border-radius-top pos-relative ${
            showDetails
              ? setNewItemId
                ? "shadow-bottom-soft"
                : "shadow-bottom"
              : "border-radius-bottom"
          } py-1`}
        >
          <div
            className="is-flex is-flex-wrap-wrap is-align-items-center pl-4"
            style={{ marginRight: "auto" }}
          >
            {/* Name */}
            <EditableComponent
              item={device}
              property={EditModeProperty.Name}
              targetVariable={EditModeTarget.Device}
              setMessageInfo={setMessageInfo}
              mutation={updateDeviceName}
              updateState={stateUpdaterFn}
            />
            <span className="is-size-7 has-text-black mx-1">
              [{device.myRole}]
            </span>
          </div>

          <div className="is-flex gap-04 ml-2">
            <IconButton
              className={`card-header-icon p-0`}
              onClick={handleDetailsButton}
              iconColor={`has-text-dark`}
              iconName={showDetails ? "chevron up" : "chevron down"}
            />

            <div>
              <a
                href={`https://${device.uuid}.viwi.eu/control`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  className={`card-header-icon p-0`}
                  onClick={handleDetailsButton}
                  iconColor={`has-text-dark`}
                  iconName="link"
                />
              </a>
            </div>

            {deviceSetId ? (
              device.myRole === DeviceRole.Administrator && (
                <IconButton
                  className={`card-header-icon py-0 pl-0 pr-2`}
                  onClick={() => handleRemoveClick()}
                  iconColor={`has-text-dark`}
                  iconName="delete"
                />
              )
            ) : (
              <Button
                className={`button is-small px-1 mr-4 ${
                  newItemId === device.id && "is-success"
                }`}
                onClick={() => {
                  if (!setNewItemId) return;
                  newItemId === device.id
                    ? setNewItemId(null)
                    : setNewItemId(device.id);
                }}
                text={newItemId === device.id ? "Selected" : "Select"}
              />
            )}
          </div>
        </div>

        {/* Middle */}
        <div
          className={`content is-flex is-flex-direction-column m-0 px-4 ${
            isLongAgo && !setNewItemId
              ? "bg-col-yellow-lighter"
              : !setNewItemId
              ? "bg-col-main-lighter"
              : ""
          } has-text-dark border-radius-bottom`}
        >
          {showDetails && (
            <div className="pt-1">
              {/* UUID */}
              <p className="mb-1 mt-2">UUID: {device.uuid}</p>

              {/* Gateway */}
              <EditableComponent
                item={device}
                property={EditModeProperty.Gateway}
                targetVariable={EditModeTarget.Device}
                setMessageInfo={setMessageInfo}
                mutation={updateDeviceGateway}
                updateState={stateUpdaterFn}
              />

              {/* Domain */}
              <EditableComponent
                item={device}
                property={EditModeProperty.Domain}
                targetVariable={EditModeTarget.Device}
                setMessageInfo={setMessageInfo}
                mutation={updateDeviceDomain}
                updateState={stateUpdaterFn}
              />

              {/* Last Connection */}
              {device.lastConnection ? (
                <p className="mt-1">
                  Last Connection:{" "}
                  {calculateTimeAgo(device.lastConnection.toLocaleString())}
                </p>
              ) : (
                <p className="mt-1">
                  Last Connection: No information available
                </p>
              )}

              {/* Users */}
              <div className="pb-0">
                {loadingUsers ? (
                  <div className="mb-2">
                    <SpinnerDark />
                  </div>
                ) : errorUsers ? (
                  <p>An error occurred while fetching data.</p>
                ) : currDeviceUsers && currDeviceUsers.length === 0 ? (
                  <p className="mb-4">No users found...</p>
                ) : (
                  <div className="mb-2">
                    <div className="is-flex is-flex-wrap-wrap">
                      {currDeviceUsers &&
                        currDeviceUsers.map(
                          ({ userId, userRole, userName }) => (
                            <div
                              className="mb-3 px-3 py-1 has-text-black border border-radius full-width"
                              key={userId}
                            >
                              <User
                                name={userName}
                                role={
                                  userRole === DeviceRole.Administrator
                                    ? userRole
                                    : `Regular ${userRole}`
                                }
                                userId={userId}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirmation Modal */}
      <ModalComponent
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setConfirmModalOpen(false)}
        actionButtons={modalActionButtons}
        isLoading={isDeleting}
        setIsLoading={setIsDeleting}
        className={`custom-modal`}
      >
        <p className="mb-4">Remove device "{device.name}" from the set?</p>
      </ModalComponent>
    </div>
  );
};

export default Device;
