import React, { Dispatch, SetStateAction, ReactNode } from "react";
import { DocumentNode, MutationFunction } from "@apollo/client";

import {
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
  DeviceRole,
  Gateway as GeneratedGateway,
  UpdateGatewayNameMutation,
  UpdateGatewayNameMutationVariables,
  UpdateGatewayDomainMutation,
  UpdateGatewayDomainMutationVariables,
  UpdateGatewayIpMutation,
  UpdateGatewayIpMutationVariables,
  UpdateDeviceNameMutation,
  UpdateDeviceNameMutationVariables,
  UpdateDeviceDomainMutation,
  UpdateDeviceDomainMutationVariables,
  UpdateDeviceGatewayMutation,
  UpdateDeviceGatewayMutationVariables,
  AddSetToSetMutation,
  AddSetToSetMutationVariables,
  AddDeviceToSetMutation,
  AddDeviceToSetMutationVariables,
  AddUserToSetMutation,
  AddUserToSetMutationVariables,
  RemoveSetMutation,
  RemoveSetMutationVariables,
  RemoveSetFromChildrenMutation,
  RemoveSetFromChildrenMutationVariables,
  UserFromIdQuery,
  UserFromIdQueryVariables,
  SearchUsersQuery,
  SearchUsersQueryVariables,
} from "../generated/typesQueriesMutations";

export interface DeviceProps {
  device: GeneratedDevices;
  expandDevices: boolean;
  setExpandedDevices: Dispatch<SetStateAction<number[] | []>>;
  deviceSetId?: number;
  deviceSets: GeneratedDeviceSets[] | null;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  allDevices: GeneratedDevices[] | null;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  allDeviceUsers: AllDeviceUsers[];
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface GatewayProps {
  gateway: GeneratedGateway;
  allGateways: GeneratedGateway[] | null;
  setAllGateways: React.Dispatch<
    React.SetStateAction<GeneratedGateway[] | null>
  >;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
}

export interface DeviceSetProps {
  deviceSet: GeneratedDeviceSets;
  deviceSets: GeneratedDeviceSets[] | null;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  allDevices: GeneratedDevices[] | null;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  allDeviceUsers: AllDeviceUsers[];
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
  allSetUsers: AllUsers[];
  setAllSetUsers: React.Dispatch<React.SetStateAction<AllUsers[]>>;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setScreenshotDevices: Dispatch<SetStateAction<GeneratedDevices[] | null>>;
}

export interface SubsetProps {
  setEntryId: number;
  parentDeviceSetId: number;
  parentDeviceSetName: string;
  deviceSetRole: DeviceRole;
  deviceSets: GeneratedDeviceSets[] | null;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  allDevices: GeneratedDevices[] | null;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  allSetUsers: AllUsers[];
  setAllSetUsers: React.Dispatch<React.SetStateAction<AllUsers[]>>;
  allDeviceUsers: AllDeviceUsers[];
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setScreenshotDevices: Dispatch<SetStateAction<GeneratedDevices[] | null>>;
}

export type UpdateMutationData =
  | UpdateGatewayNameMutation
  | UpdateGatewayDomainMutation
  | UpdateGatewayIpMutation
  | UpdateDeviceNameMutation
  | UpdateDeviceDomainMutation
  | UpdateDeviceGatewayMutation;

export type UpdateMutationVariables =
  | UpdateGatewayNameMutationVariables
  | UpdateGatewayDomainMutationVariables
  | UpdateGatewayIpMutationVariables
  | UpdateDeviceNameMutationVariables
  | UpdateDeviceDomainMutationVariables
  | UpdateDeviceGatewayMutationVariables;

export enum EditModeProperty {
  Name = "name",
  Domain = "domain",
  Ip = "ip",
  Gateway = "gatewayId",
}

export enum EditModeTarget {
  Device = "device",
  Gateway = "gateway",
}

export interface EditOptionProps {
  item: GeneratedGateway | GeneratedDevices;
  property: EditModeProperty;
  targetVariable: EditModeTarget;
  mutation: any;
  updateState: (...args: any[]) => void;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
}

export interface DeviceUsersData {
  [key: string]: string;
}

export interface AllUsers {
  deviceSetId: number;
  deviceSetUsers: Array<{
    userId: number;
    userRole: DeviceRole;
    userName: string;
  }>;
}

export interface AllDeviceUsers {
  deviceId: number;
  deviceUsers: Array<{
    userId: number;
    userRole: DeviceRole;
    userName: string;
  }>;
}

export interface SetSettingsProps {
  deviceSetId: number;
  parentDeviceSetId?: number;
  parentDeviceSetName?: string;
  deviceSets: GeneratedDeviceSets[] | null;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  allDevices: GeneratedDevices[] | null;
  deviceSetRole: DeviceRole;
  deviceSetName: string;
  allSetUsers: AllUsers[];
  setAllSetUsers: React.Dispatch<React.SetStateAction<AllUsers[]>>;
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  allDeviceUsers: AllDeviceUsers[];
}

export interface DeviceSetUsersProps {
  deviceSetId: number;
  deviceSetRole: DeviceRole;
  deviceSetName: string;
  deviceSets: GeneratedDeviceSets[] | null;
  allSetUsers: AllUsers[];
  setAllSetUsers: React.Dispatch<React.SetStateAction<AllUsers[]>>;
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
}

export interface SearchUserProps {
  searchKey: string;
  selectedOption: string;
  queryToUse: DocumentNode;
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
  userData:
    | Array<{ id: number; name: string }>
    | { id: number; name: string }
    | null;
  setUserData: React.Dispatch<
    React.SetStateAction<
      Array<{ id: number; name: string }> | { id: number; name: string } | null
    >
  >;
}

export interface SearchUsersProps {
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
  role?: DeviceRole;
  setRole?: React.Dispatch<React.SetStateAction<DeviceRole>>;
}

export interface AllDevicesListProps {
  allDevices: GeneratedDevices[] | null;
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;

  deviceSets: GeneratedDeviceSets[] | null;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  allDeviceUsers: AllDeviceUsers[];
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
}

export interface AllSetListProps {
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
  deviceSets?: GeneratedDeviceSets[] | null;
  subsets?: GeneratedDeviceSets[] | null;
  setSubsets?: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
}

export type UserQueryData = UserFromIdQuery | SearchUsersQuery;

export type UserQueryVariables =
  | UserFromIdQueryVariables
  | SearchUsersQueryVariables;

export enum SettingOptions {
  AddUser = "add user",
  AddDevice = "add device",
  AddChild = "add subset",
  CreateSubset = "create subset",
  RemoveSet = "remove set",
  RemoveChild = "remove subset",
}

export type UpdateSetMutationData =
  | AddSetToSetMutation
  | AddDeviceToSetMutation
  | AddUserToSetMutation
  | RemoveSetMutation
  | RemoveSetFromChildrenMutation;

export type UpdateSetMutationVariables =
  | AddSetToSetMutationVariables
  | AddDeviceToSetMutationVariables
  | AddUserToSetMutationVariables
  | RemoveSetMutationVariables
  | RemoveSetFromChildrenMutationVariables;

export interface SettingOptionProps {
  option: SettingOptions;
  deviceSetId?: number;
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
  mutation?: MutationFunction<
    UpdateSetMutationData,
    UpdateSetMutationVariables
  >;
  mutationVariables?: UpdateSetMutationVariables;
  updateState?: () => void;
  deviceSetRole?: DeviceRole;
  parentDeviceSetId?: number;
  role?: DeviceRole;
  setRole?: React.Dispatch<React.SetStateAction<DeviceRole>>;
  deviceSetName?: string;
  parentDeviceSetName?: string;
  allDevices?: GeneratedDevices[] | null;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
  deviceSets: GeneratedDeviceSets[] | null;
  subsets: GeneratedDeviceSets[] | null;
  setSubsets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  setAllDevices: React.Dispatch<
    React.SetStateAction<GeneratedDevices[] | null>
  >;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;

  allDeviceUsers: AllDeviceUsers[];
  setAllDeviceUsers: React.Dispatch<React.SetStateAction<AllDeviceUsers[]>>;
}

export interface MessageProps {
  message: string | null;
  type: "is-success" | "is-danger";
  onClose: () => void;
}

export interface MessageInfo {
  message: string | null;
  messageType: "is-success" | "is-danger";
}

export interface RadioButtonProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export interface InputProps {
  type: "text" | "number";
  name: string;
  placeholder: string;
  value: string | number | null | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSmall?: boolean;
}

export interface ButtonProps {
  onClick?: () => void;
  className?: string;
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

export interface IconButtonProps {
  onClick?: () => void;
  iconColor?: string;
  className?: string;
  iconName: string;
}

export interface SetSettingButtonProps {
  onClick: () => void;
  danger?: boolean;
  children: ReactNode;
}

export interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  actionButtons?: Array<{ text: string; onClick: () => void }>;
  isLoading?: boolean;
  setIsLoading?: (value: boolean) => void;
  children: React.ReactNode;

  className?: string;
  newItemId?: number | null;
  setNewItemId?: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface UserProps {
  name: string;
  role: string;
  userId: number;
}

export interface CreateSetProps {
  deviceSetId?: number;
  deviceSets?: GeneratedDeviceSets[] | null;
  setDeviceSets: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  setSubsets?: Dispatch<SetStateAction<GeneratedDeviceSets[] | null>>;
  setMessageInfo: React.Dispatch<React.SetStateAction<MessageInfo>>;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettings?: React.Dispatch<React.SetStateAction<boolean>>;
  isSmall: boolean;
}
