import React from "react";
import { RadioButtonProps } from "../../helpers/interfacesTypesEnums";

function RadioButton({
  name,
  value,
  checked,
  onChange,
  label,
}: RadioButtonProps) {
  return (
    <label className="radio">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="mr-1"
      />
      {label}
    </label>
  );
}

export default RadioButton;
