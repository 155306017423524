import React from "react";

export function Spinner() {
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      style={{ height: "3rem", width: "100%" }}
    >
      <div style={{ transform: "scale(.5)" }}>
        <span className="loader"></span>
      </div>
    </div>
  );
}

export function SpinnerLight() {
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center has-background-light border-radius"
      style={{ height: "3rem", width: "100%" }}
    >
      <div style={{ transform: "scale(.5)" }}>
        <span className="loader"></span>
      </div>
    </div>
  );
}

export function SpinnerDark() {
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      style={{ height: "3rem", width: "100%" }}
    >
      <div style={{ transform: "scale(.5)" }}>
        <span className="loader black"></span>
      </div>
    </div>
  );
}

export function SpinnerCard() {
  return (
    <div className="card" style={{ height: "22rem" }}>
      <div
        className="card-header has-background-light"
        style={{ width: "100%", height: "10%" }}
      ></div>
      <div
        className="card-content is-flex is-align-items-center is-justify-content-center"
        style={{ width: "100%", height: "80%" }}
      >
        <span className="loader"></span>
      </div>
      <div
        className="card-footer"
        style={{ width: "100%", height: "10%" }}
      ></div>
    </div>
  );
}
