import React, { useEffect, useState } from "react";

import { DeviceSetProps } from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  Device as GeneratedDevices,
} from "../generated/typesQueriesMutations";

import Device from "./Device";
import Subset from "./Subset";
import SetSettings from "./SetSettings";
import SetUsers from "./SetUsers";
import Button from "./elements/Button";
import IconButton from "./elements/IconButton";

import { useExpandDevices } from "../hooks/useExpandDevices";

const DeviceSet = ({
  deviceSet,
  deviceSets,
  setDeviceSets,
  subsets,
  setSubsets,
  allDevices,
  setAllDevices,
  allDeviceUsers,
  setAllDeviceUsers,
  allSetUsers,
  setAllSetUsers,
  setMessageInfo,
  setScreenshotDevices,
}: DeviceSetProps): JSX.Element => {
  // Show & Hide States
  const [showOptions, setShowOptions] = useState(false);

  // Expand All Button
  const setDeviceIds = deviceSet.devices.map((device) => device.id);
  const { expandDevices, handleExpandButton, setExpandedDevices } =
    useExpandDevices(setDeviceIds);

  useEffect(() => {
    setScreenshotDevices((prev) =>
      prev ? [...deviceSet.devices, ...prev] : [...deviceSet.devices]
    );
  }, [deviceSet.devices, setScreenshotDevices]);

  return (
    <li className="card mb-5">
      {/* Card Header */}
      <div className="card-header has-background-dark is-justify-content-space-between">
        <div className="is-flex is-flex-wrap-wrap is-align-items-center pl-4">
          <h3 className="subtitle card-header-title has-text-light p-0 m-0 mr-2">
            {deviceSet.name}
          </h3>
          <span className="is-size-7 has-text-light py-1">
            [{deviceSet.myRole}]
          </span>
        </div>

        <IconButton
          className="card-header-icon has-text-light py-1"
          onClick={() => setShowOptions((o) => !o)}
          iconName={`${showOptions ? "chevron up" : "chevron down"}`}
          iconColor="white"
        />
      </div>

      {/* Card Content */}
      <div className="card-content px-0 py-0">
        <div className="content px-0 py-0">
          {/* Options */}
          {showOptions && (
            <div className={`border has-background-light py-3 px-4 mb-6`}>
              <div className="is-flex is-flex-wrap-wrap gap-04 mb-2">
                <Button
                  text={
                    expandDevices
                      ? "Collapse All Devices"
                      : "Expand All Devices"
                  }
                  className="button is-small"
                  onClick={handleExpandButton}
                />

                <SetSettings
                  deviceSetId={deviceSet.id}
                  deviceSetRole={deviceSet.myRole}
                  allDevices={allDevices}
                  deviceSets={deviceSets}
                  setDeviceSets={setDeviceSets}
                  subsets={subsets}
                  setSubsets={setSubsets}
                  deviceSetName={deviceSet.name}
                  allSetUsers={allSetUsers}
                  setAllSetUsers={setAllSetUsers}
                  setAllDeviceUsers={setAllDeviceUsers}
                  setMessageInfo={setMessageInfo}
                  setShowSettings={setShowOptions}
                  setAllDevices={setAllDevices}
                  allDeviceUsers={allDeviceUsers}
                />
              </div>

              <div>
                <div className="py-2">
                  <span className="mr-1">
                    <i className="fa-solid fa-people-arrows"></i>
                  </span>
                  <span className="mr-1">Users</span>
                </div>

                <div>
                  <SetUsers
                    deviceSetId={deviceSet.id}
                    deviceSetName={deviceSet.name}
                    deviceSetRole={deviceSet.myRole}
                    allSetUsers={allSetUsers}
                    setAllSetUsers={setAllSetUsers}
                    deviceSets={deviceSets}
                    setAllDeviceUsers={setAllDeviceUsers}
                    setMessageInfo={setMessageInfo}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Main Content */}
          <div className="p-4">
            <div>
              {deviceSet.devices && deviceSet.devices.length > 0 ? (
                <ul className="columns is-multiline mb-6">
                  {deviceSet.devices.map(
                    (device: GeneratedDevices, index) =>
                      device && (
                        <li
                          className="column is-one-quarter-desktop is-half-tablet p-1"
                          key={index}
                        >
                          <Device
                            device={device}
                            expandDevices={expandDevices}
                            setExpandedDevices={setExpandedDevices}
                            deviceSetId={deviceSet.id}
                            deviceSets={deviceSets}
                            setDeviceSets={setDeviceSets}
                            subsets={subsets}
                            setSubsets={setSubsets}
                            allDevices={allDevices}
                            setAllDevices={setAllDevices}
                            allDeviceUsers={allDeviceUsers}
                            setAllDeviceUsers={setAllDeviceUsers}
                            setMessageInfo={setMessageInfo}
                          />
                        </li>
                      )
                  )}
                </ul>
              ) : (
                <p className="pb-4">No devices found...</p>
              )}
            </div>

            <div>
              {deviceSet.deviceSets?.length > 0 ? (
                <ul className="columns is-desktop is-multiline">
                  {deviceSet.deviceSets.map((childSet: GeneratedDeviceSets) => (
                    <li
                      className="column is-half-desktop p-1"
                      key={childSet.id}
                    >
                      <Subset
                        setEntryId={childSet.id}
                        parentDeviceSetId={deviceSet.id}
                        parentDeviceSetName={deviceSet.name}
                        deviceSetRole={childSet.myRole}
                        deviceSets={deviceSets}
                        setDeviceSets={setDeviceSets}
                        subsets={subsets}
                        setSubsets={setSubsets}
                        allDevices={allDevices}
                        setAllDevices={setAllDevices}
                        allSetUsers={allSetUsers}
                        setAllSetUsers={setAllSetUsers}
                        setMessageInfo={setMessageInfo}
                        allDeviceUsers={allDeviceUsers}
                        setAllDeviceUsers={setAllDeviceUsers}
                        setScreenshotDevices={setScreenshotDevices}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Subsets found...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default DeviceSet;
