import React, { useState, useEffect } from "react";
import { ApolloError } from "@apollo/client";

import {
  SettingOptions,
  SettingOptionProps,
} from "../helpers/interfacesTypesEnums";
import { DeviceRole } from "../generated/typesQueriesMutations";

import SetSettingButton from "./elements/SetSettingsButton";
import ModalComponent from "./elements/Modal";
import SearchDevices from "./SearchDevices";
import SearchUsers from "./SearchUsers";
import SearchSets from "./SearchSets";
import CreateSet from "./CreateSet";

const SettingOption = ({
  option,
  deviceSetId,
  newItemId,
  setNewItemId,
  mutation,
  mutationVariables,
  updateState,
  deviceSetRole,
  parentDeviceSetId,
  role,
  setRole,
  deviceSetName,
  parentDeviceSetName,
  setMessageInfo,
  setShowSettings,
  allDevices,
  deviceSets,
  subsets,
  setSubsets,
  setAllDevices,
  setDeviceSets,
  allDeviceUsers,
  setAllDeviceUsers,
}: SettingOptionProps) => {
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [parentDeviceSet, setParentDeviceSet] = useState<number | null>(null);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ModalActionButtons = [
    {
      text:
        option === SettingOptions.RemoveSet
          ? "Delete"
          : parentDeviceSetId
          ? "Remove"
          : "Add",
      onClick: () => handleOptionMutation(),
    },
  ];

  // Show input
  const [showInput, setShowInput] = useState(false);

  // Use local state for mutation variables
  const [localMutationVariables, setLocalMutationVariables] =
    useState(mutationVariables);

  // Watch for changes in mutationVariables prop
  useEffect(() => {
    setLocalMutationVariables(mutationVariables);
  }, [mutationVariables]);

  // Handle delete set or remove set from subset
  const handleOptionDelete = (parentDevice?: number) => {
    parentDevice ? setParentDeviceSet(parentDevice) : setParentDeviceSet(null);

    setIsModalOpen(true);
  };

  // Handle Option Mutation
  const handleOptionMutation = async () => {
    try {
      // Set loading state
      setIsLoading(true);

      // Execute mutation
      if (!mutation) return;
      const response = await mutation({ variables: localMutationVariables });

      if (response.errors && response.errors.length > 0) {
        const errorMessages = response.errors
          .map((error: any) => error.message)
          .join(", ");
        const customError = new Error(
          "Error occurred while performing the mutation: " + errorMessages
        );

        throw customError;
      }

      // Update state
      updateState && updateState();

      // Show success message
      setMessageInfo({
        message: `${
          option.split(" ")[1].charAt(0).toUpperCase() +
          option.split(" ")[1].slice(1)
        } ${
          option.split(" ")[0] === "add" ? "added" : "deleted"
        } successfully.`,
        messageType: "is-success",
      });
    } catch (error) {
      // Handle errors
      error instanceof ApolloError
        ? setMessageInfo({
            message: `Something went wrong: ${error.message}`,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message: `An unknown error occurred while ${
              option.split(" ")[0] === "add" ? "adding" : "removing"
            } the ${option.split(" ")[1]}. ${error}`,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      // Reset loading state
      setIsLoading(false);
      // Reset states
      setShowSettings(false);
      setNewItemId && setNewItemId(null);
    }
  };

  return (
    <div>
      {/* Add Device | Add Child | Add User */}
      {(option === SettingOptions.AddDevice ||
        option === SettingOptions.AddChild ||
        option === SettingOptions.AddUser) && (
        <SetSettingButton onClick={() => setIsModalOpen(true)}>
          {option
            .split(" ")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </SetSettingButton>
      )}

      {/* Modal Create Subset */}
      {option === SettingOptions.CreateSubset &&
        (showInput ? (
          <CreateSet
            deviceSetId={deviceSetId}
            deviceSets={deviceSets}
            setDeviceSets={setDeviceSets}
            setSubsets={setSubsets}
            setMessageInfo={setMessageInfo}
            setShowInput={setShowInput}
            setShowSettings={setShowSettings}
            isSmall={true}
          />
        ) : (
          <SetSettingButton onClick={() => setShowInput(true)}>
            {option
              .split(" ")
              .map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" ")}
          </SetSettingButton>
        ))}

      {/* Delete Set */}
      {option === SettingOptions.RemoveSet &&
        deviceSetRole === DeviceRole.Administrator && (
          <SetSettingButton onClick={() => handleOptionDelete()} danger>
            Delete Set
          </SetSettingButton>
        )}

      {/* Remove Subset From Superset */}
      {parentDeviceSetId && (
        <SetSettingButton
          onClick={() => handleOptionDelete(parentDeviceSetId)}
          danger
        >
          Delete from Superset
        </SetSettingButton>
      )}

      {/* Modal */}
      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setNewItemId && setNewItemId(null);
          setParentDeviceSet(null);
        }}
        actionButtons={ModalActionButtons}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        newItemId={newItemId}
        setNewItemId={setNewItemId}
        className={`${
          option === SettingOptions.RemoveSet ||
          option === SettingOptions.RemoveChild
            ? "custom-modal"
            : ""
        }`}
      >
        {/* Modal Add Device */}
        {option === SettingOptions.AddDevice && allDevices && (
          <SearchDevices
            allDevices={allDevices}
            newItemId={newItemId as number}
            setNewItemId={
              setNewItemId as React.Dispatch<
                React.SetStateAction<number | null>
              >
            }
            deviceSets={deviceSets || []}
            setAllDevices={setAllDevices}
            setMessageInfo={setMessageInfo}
            setDeviceSets={setDeviceSets}
            subsets={subsets || []}
            setSubsets={setSubsets}
            allDeviceUsers={allDeviceUsers}
            setAllDeviceUsers={setAllDeviceUsers}
          />
        )}

        {/* Modal Add User */}
        {option === SettingOptions.AddUser && (
          <SearchUsers
            newItemId={newItemId as number}
            setNewItemId={
              setNewItemId as React.Dispatch<
                React.SetStateAction<number | null>
              >
            }
            role={role}
            setRole={setRole}
          />
        )}

        {/* Modal Add Subset */}
        {option === SettingOptions.AddChild && (
          <SearchSets
            newItemId={newItemId as number}
            setNewItemId={
              setNewItemId as React.Dispatch<
                React.SetStateAction<number | null>
              >
            }
            deviceSets={deviceSets}
            subsets={subsets}
            setSubsets={setSubsets}
          />
        )}

        {/* Modal Delete */}
        {(option === SettingOptions.RemoveSet || parentDeviceSetId) && (
          <p className="mb-4">
            {parentDeviceSet
              ? `Remove set "${deviceSetName}" from "${parentDeviceSetName}'s" subsets?`
              : `Delete set "${deviceSetName}"?`}
          </p>
        )}
      </ModalComponent>
    </div>
  );
};

export default SettingOption;
