import React, { useState } from "react";
import "./custom.scss";

import DeviceSetList from "./components/Sets";
import GatewaysList from "./components/Gateways";
import Footer from "./components/Footer";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { OidcSecureWrapper } from "./OidcSecureWrapper";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import Header from "./components/Header";

export const GQL_ENDPOINT = "/api/gql";

// get the authentication token from local storage if it exists
export const getToken = () => {
  const storedToken = JSON.parse(
    String(window.sessionStorage.getItem("oidc.default"))
  )?.tokens?.accessToken;
  return storedToken;
};

const App = () => {
  return (
    <OidcSecureWrapper>
      <AuthenticatedApp />
    </OidcSecureWrapper>
  );
};

const AuthenticatedApp = () => {
  const [error, setError] = useState<string | null>(null);
  const token = getToken();
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ``,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      setError(
        graphQLErrors
          .map(({ message, locations, path }) => {
            return `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`;
          })
          .join("\n")
      );
    }

    if (networkError) setError(`[Network error]: ${networkError}`);
  });

  if (error) console.error(error);

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: errorLink.concat(
      authLink.concat(
        createUploadLink({
          uri: GQL_ENDPOINT,
        }) as unknown as ApolloLink
      )
    ),
  });

  return (
    <ApolloProvider client={client}>
      <div className="container">
        <Header />
        <DeviceSetList />
        <GatewaysList />
        <Footer />
      </div>
    </ApolloProvider>
  );
};

export default App;
