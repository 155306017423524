import React from "react";
import { AllDevicesListProps } from "../helpers/interfacesTypesEnums";
import { Device as GeneratedDevice } from "../generated/typesQueriesMutations";

import Device from "./Device";

import Button from "./elements/Button";
import { useExpandDevices } from "../hooks/useExpandDevices";

const SearchDevices = ({
  allDevices,
  newItemId,
  setNewItemId,
  deviceSets,
  setAllDevices,
  setMessageInfo,
  setDeviceSets,
  subsets,
  setSubsets,
  allDeviceUsers,
  setAllDeviceUsers,
}: AllDevicesListProps): JSX.Element => {
  // Expand All Button
  const deviceIds = allDevices?.map((device) => device.id);

  const { expandDevices, handleExpandButton, setExpandedDevices } =
    useExpandDevices(deviceIds);

  return (
    <div className="mb-6">
      {allDevices && allDevices.length > 0 ? (
        <div>
          <div className="has-text-centered">
            <h2 className="title mb-5">Choose the Device</h2>

            {allDevices.length > 1 && (
              <Button
                className="button is-small mb-5"
                onClick={handleExpandButton}
                text={
                  expandDevices ? "Collapse All Devices" : "Expand All Devices"
                }
              />
            )}
          </div>

          <div className="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center gap-09">
            {allDevices.map((device: GeneratedDevice) => (
              <div
                key={device.id}
                className="is-flex is-flex-direction-column"
                style={{ minWidth: "15rem" }}
              >
                <Device
                  device={device}
                  allDevices={allDevices}
                  setAllDevices={setAllDevices}
                  deviceSets={deviceSets}
                  setDeviceSets={setDeviceSets}
                  subsets={subsets}
                  setSubsets={setSubsets}
                  allDeviceUsers={allDeviceUsers}
                  setAllDeviceUsers={setAllDeviceUsers}
                  expandDevices={expandDevices}
                  setExpandedDevices={setExpandedDevices}
                  setMessageInfo={setMessageInfo}
                  newItemId={newItemId}
                  setNewItemId={setNewItemId}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p>No devices found...</p>
        </div>
      )}
    </div>
  );
};

export default SearchDevices;
