import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { MessageInfo } from "../helpers/interfacesTypesEnums";
import {
  Gateway as GeneratedGateway,
  AllGatewaysDocument,
} from "../generated/typesQueriesMutations";

import Gateway from "./Gateway";
import { SpinnerCard } from "./elements/Spinner";
import Message from "./elements/Message";

const GatewaysList = (): JSX.Element => {
  const [allGateways, setAllGateways] = useState<GeneratedGateway[] | null>(
    null
  );

  const [messageInfo, setMessageInfo] = useState<MessageInfo>({
    message: null,
    messageType: "is-success",
  });

  const { loading, error } = useQuery(AllGatewaysDocument, {
    onCompleted: (data) => {
      setAllGateways(data.allGateways || []);
    },
  });

  return (
    <section
      className="section"
      style={{ paddingBottom: "10rem", marginBottom: "auto" }}
    >
      <h2 className="title is-flex custom-center">Gateways</h2>
      {loading ? (
        <div className="columns">
          <div className="column is-one-third">
            <SpinnerCard />
          </div>
          <div className="column is-one-third">
            <SpinnerCard />
          </div>
          <div className="column is-one-third">
            <SpinnerCard />
          </div>
        </div>
      ) : error ? (
        <p className="custom-center">An error occurred while fetching data.</p>
      ) : allGateways && allGateways.length > 0 ? (
        <div className="is-flex is-flex-wrap-wrap is-align-items-flex-start custom-center card-gap">
          {allGateways.map((gateway: GeneratedGateway) => (
            <Gateway
              key={gateway.id}
              gateway={gateway}
              allGateways={allGateways}
              setAllGateways={setAllGateways}
              setMessageInfo={setMessageInfo}
            />
          ))}
        </div>
      ) : (
        <div>
          <p className="custom-center">No gateways found...</p>
        </div>
      )}

      {/* Message Modal */}
      {messageInfo.message && (
        <Message
          message={messageInfo.message}
          type={messageInfo.messageType}
          onClose={() => {
            setMessageInfo({ message: null, messageType: "is-success" });
          }}
        />
      )}
    </section>
  );
};

export default GatewaysList;
