import React from "react";
import { InputProps } from "../../helpers/interfacesTypesEnums";

function Input({
  type,
  name,
  placeholder,
  value,
  onChange,
  isSmall,
}: InputProps) {
  return (
    <input
      className={`input border-radius ${isSmall && "is-small"}`}
      style={{ height: "100%" }}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
    />
  );
}

export default Input;
