import React, { useState } from "react";
import ModalComponent from "./elements/Modal";

import { useOidc } from "@axa-fr/react-oidc";
import Button from "./elements/Button";

const SignOut = () => {
  const { logout } = useOidc();

  const [modalOpen, setModalOpen] = useState(false);
  const modalActionButtons = [
    {
      text: "Sign Out",
      onClick: () => logout(),
    },
  ];

  return (
    <>
      <Button
        text={"Sign out"}
        onClick={() => setModalOpen(true)}
        className="button is-outlined has-text-weight-bold"
      />

      <ModalComponent
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        actionButtons={modalActionButtons}
        className={`custom-modal`}
      >
        <p className="mb-4">Are you sure you want to sign out?</p>
      </ModalComponent>
    </>
  );
};

export default SignOut;
