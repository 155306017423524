import React from "react";
import SignOut from "./SignOut";

const Header = () => {
  return (
    <div
      className="navbar is-flex is-justify-content-space-between py-4"
      style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
    >
      <div className="navbar-start is-flex is-align-items-center">
        <svg
          className="mr-2"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M19.8836 40C17.1993 40 14.5953 39.4711 12.1437 38.4281C9.77588 37.4206 7.64938 35.9785 5.82348 34.1418C3.99759 32.305 2.56385 30.1662 1.5626 27.7845C0.525657 25.3192 0 22.6999 0 20C0 17.3003 0.525657 14.6811 1.56237 12.2155C2.56385 9.83382 3.99736 7.69504 5.82325 5.85825C7.64914 4.02146 9.77564 2.57938 12.1434 1.57187C14.5951 0.528902 17.1993 0 19.8836 0C22.568 0 25.172 0.528902 27.6236 1.57187C29.9914 2.57938 32.1179 4.02146 33.9438 5.85825C35.7697 7.69504 37.2032 9.83382 38.2047 12.2155C39.2414 14.6813 39.7673 17.3003 39.7673 20C39.7673 22.6997 39.2416 25.3189 38.2047 27.7847C37.2032 30.1662 35.7697 32.3052 33.9438 34.142C32.1177 35.9788 29.9914 37.4209 27.6236 38.4284C25.172 39.4713 22.5678 40 19.8836 40ZM19.8836 0.347657C17.2463 0.347657 14.688 0.867288 12.2797 1.89172C9.95341 2.88138 7.86423 4.29843 6.06986 6.10323C4.27548 7.90803 2.86678 10.0097 1.88291 12.3499C0.864275 14.7729 0.347657 17.3467 0.347657 20C0.347657 22.6533 0.864275 25.2271 1.88291 27.6498C2.86701 29.99 4.27571 32.0917 6.06986 33.8965C7.864 35.7013 9.95341 37.1184 12.2797 38.1081C14.688 39.1327 17.2463 39.6521 19.8836 39.6521C22.521 39.6521 25.0793 39.1325 27.4876 38.1081C29.8139 37.1184 31.9031 35.7013 33.6972 33.8965C35.4916 32.0917 36.9003 29.99 37.8842 27.6498C38.903 25.2269 39.4194 22.6533 39.4194 20C39.4194 17.3467 38.9028 14.7729 37.8842 12.3502C36.9001 10.01 35.4914 7.90827 33.6972 6.10346C31.9031 4.29866 29.8137 2.88161 27.4876 1.89195C25.079 0.867288 22.5207 0.347657 19.8836 0.347657Z"
              fill="black"
            />
            <path
              d="M24.1504 6.88081L21.1981 13.4476L18.163 6.68774H13.8861L21.205 22.9117L20.0944 25.1467L11.8169 6.68774H5.24988L20.1495 39.7979L24.454 30.0579L21.2256 22.9117L24.1504 16.4829L27.1578 22.9117L34.4146 6.68774H30.1101L27.1027 13.5306L24.1504 6.88081Z"
              fill="black"
            />
            <path
              d="M26.1719 23.4359L23.1846 22.1113V24.8008L26.1719 23.4359Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="39.7673" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1
          className="has-text-dark is-size-5"
          style={{
            display: window.innerWidth < 600 ? "none" : "block",
            fontWeight: "700",
          }}
        >
          VideowindoW
        </h1>
      </div>
      <div className="navbar-end">
        <SignOut />
      </div>
    </div>
  );
};

export default Header;
