import { useState, useEffect } from "react";

export function useExpandDevices(initialDeviceIds: number[] = []) {
  const [expandDevices, setExpandDevices] = useState(false);
  const [expandedDevices, setExpandedDevices] = useState<number[]>([]);

  const handleExpandButton = () => {
    setExpandDevices(!expandDevices);

    expandDevices
      ? setExpandedDevices([])
      : setExpandedDevices(initialDeviceIds);
  };

  useEffect(() => {
    if (arraysAreEqual(expandedDevices, initialDeviceIds))
      setExpandDevices(true);

    if (!expandedDevices.length) setExpandDevices(false);
  }, [expandedDevices, initialDeviceIds]);

  return { expandDevices, setExpandedDevices, handleExpandButton };
}

function arraysAreEqual(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
}
