import React from "react";
import { IconButtonProps } from "../../helpers/interfacesTypesEnums";

const IconButton = ({
  iconName,
  iconColor = "has-text-black",
  className,
  onClick,
}: IconButtonProps) => {
  const deleteIcon = <i className="fa-solid fa-xmark"></i>;
  const editIcon = (
    <span>
      <i className="fa-solid fa-pen fa-xs"></i>
    </span>
  );

  const plusIcon = (
    <span>
      <i className="fa-solid fa-square-plus"></i>
    </span>
  );
  const settingsIcon = (
    <span
      className="icon has-text-light"
      style={{ transform: "rotate(90deg)" }}
    >
      <i className="fa-solid fa-ellipsis-vertical fa-xl"></i>
    </span>
  );
  const chevronDownIcon = (
    <span className={iconColor}>
      <i className="fa-solid fa-chevron-down"></i>
    </span>
  );
  const chevronUpIcon = <i className="fa-solid fa-chevron-up"></i>;
  const circleRight = <i className="fa-regular fa-circle-right"></i>;
  const expand = (
    <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
  );
  const collapse = (
    <i className="fa-solid fa-down-left-and-up-right-to-center"></i>
  );

  return (
    <button
      className={`${className}`}
      onClick={onClick}
      aria-label={iconName.charAt(0).toUpperCase() + iconName.slice(1)}
    >
      <span className={`icon ${iconColor}`}>
        {iconName === "delete" && deleteIcon}
        {iconName === "edit" && editIcon}
        {iconName === "show input" && plusIcon}
        {iconName === "settings" && settingsIcon}
        {iconName === "chevron down" && chevronDownIcon}
        {iconName === "chevron up" && chevronUpIcon}
        {iconName === "link" && circleRight}
        {iconName === "expand" && expand}
        {iconName === "collapse" && collapse}
      </span>
    </button>
  );
};

export default IconButton;
