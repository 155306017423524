import React, { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";

import {
  GatewayProps,
  EditModeProperty,
  EditModeTarget,
} from "../helpers/interfacesTypesEnums";
import {
  Gateway as GeneratedGateway,
  UpdateGatewayNameDocument,
  UpdateGatewayDomainDocument,
  UpdateGatewayIpDocument,
  RemoveGatewayDocument,
} from "../generated/typesQueriesMutations";

import EditableComponent from "./EditOption";
import ModalComponent from "./elements/Modal";
import IconButton from "./elements/IconButton";

const Gateway = ({
  gateway,
  allGateways,
  setAllGateways,
  setMessageInfo,
}: GatewayProps) => {
  // Manage the state of the confirmation modal
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const modalActionButtons = [
    { text: "Delete", onClick: () => confirmRemove() },
  ];

  // Load state
  const [isDeleting, setIsDeleting] = useState(false);

  // Mutations
  const [updateGatewayName] = useMutation(UpdateGatewayNameDocument);
  const [updateGatewayDomain] = useMutation(UpdateGatewayDomainDocument);
  const [updateGatewayIp] = useMutation(UpdateGatewayIpDocument);

  // State updater function
  const stateUpdaterFn = function (updatedItem: GeneratedGateway) {
    const updatedAllItems = allGateways?.map((item) => {
      return updatedItem;
    });

    setAllGateways(updatedAllItems || allGateways);
  };

  // Handle removing a gateway
  const [removeGateway] = useMutation(RemoveGatewayDocument);

  const handleRemoveClick = () => {
    setConfirmModalOpen(true);
  };

  const confirmRemove = async () => {
    try {
      setIsDeleting(true);

      await removeGateway({
        variables: {
          gateway: gateway.id,
        },
      });

      if (allGateways !== null) {
        const updatedGateways = allGateways.filter(
          (gatewayItem) => gatewayItem.id !== gateway.id
        );
        setAllGateways(updatedGateways);
      }

      setMessageInfo({
        message: "Gateway deleted successfully.",
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: "Error deleting gateway: " + error.message,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message:
              "An unknown error occurred while deleting the gateway." + error,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      setIsDeleting(false);
      setConfirmModalOpen(false);
    }
  };

  return (
    <div className="card card-width-wide" key={gateway.id}>
      <header className="card-header has-background-dark">
        <div className="card-header-title is-flex is-flex-direction-column is-align-items-flex-start py-1">
          <EditableComponent
            item={gateway}
            property={EditModeProperty.Name}
            targetVariable={EditModeTarget.Gateway}
            setMessageInfo={setMessageInfo}
            mutation={updateGatewayName}
            updateState={stateUpdaterFn}
          />
        </div>

        <IconButton
          className={`card-header-icon py-0 px-2`}
          iconColor="has-text-light"
          onClick={() => handleRemoveClick()}
          iconName="delete"
        />
      </header>

      <div className="card-content px-4 py-3">
        <div className="content">
          <div>
            <p className="mb-3">UUID: {gateway.uuid}</p>

            <div className="mb-3">
              <EditableComponent
                item={gateway}
                property={EditModeProperty.Domain}
                targetVariable={EditModeTarget.Gateway}
                setMessageInfo={setMessageInfo}
                mutation={updateGatewayDomain}
                updateState={stateUpdaterFn}
              />
            </div>
            <div className="mb-3">
              <EditableComponent
                item={gateway}
                property={EditModeProperty.Ip}
                targetVariable={EditModeTarget.Gateway}
                setMessageInfo={setMessageInfo}
                mutation={updateGatewayIp}
                updateState={stateUpdaterFn}
              />
            </div>
            <p>Number of Connected Devices: {gateway.connectedDevices}</p>
            <p>Connections: {gateway.connections || "No connections"}</p>
            <p>Bandwidth: {gateway.bandwidth || "N/A"}</p>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ModalComponent
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setConfirmModalOpen(false)}
        actionButtons={modalActionButtons}
        isLoading={isDeleting}
        setIsLoading={setIsDeleting}
        className={`custom-modal`}
      >
        <p className="mb-4">Delete gateway "{gateway.name}"?</p>
      </ModalComponent>
    </div>
  );
};

export default Gateway;
