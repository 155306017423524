import React, { useState, useEffect } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";

import {
  DeviceSetUsersProps,
  AllUsers,
  AllDeviceUsers,
} from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  DeviceRole,
  AllSetUsersDocument,
  RemoveUserFromSetDocument,
  RemoveUserAllDocument,
} from "../generated/typesQueriesMutations";

import ModalComponent from "./elements/Modal";
import { SpinnerLight } from "./elements/Spinner";
import User from "./User";
import IconButton from "./elements/IconButton";

const SetUsers = ({
  deviceSetId,
  deviceSetName,
  deviceSetRole,
  deviceSets,
  allSetUsers,
  setAllSetUsers,
  setAllDeviceUsers,
  setMessageInfo,
}: DeviceSetUsersProps) => {
  // States
  const [currSetUsers, setCurrSetUsers] = useState<
    Array<{ userId: number; userRole: DeviceRole; userName: string }>
  >([]);

  // Manage the state of the confirmation modal
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const modalActionButtons = [
    {
      text: `Remove user from set "${deviceSetName}"`,
      onClick: () => handleRemoveUser(selectedUserId),
    },
    {
      text: "Remove user from all sets",
      onClick: () => handleRemoveUserAll(selectedUserId),
    },
  ];
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserName, setSelectedUserName] = useState<string | null>(null);

  // Load state
  const [isDeleting, setIsDeleting] = useState(false);

  // Retrieve the users data
  const { loading: loadingUsers, error: errorUsers } = useQuery(
    AllSetUsersDocument,
    {
      variables: { deviceSet: deviceSetId },

      // State update
      onCompleted: (data) => {
        // Find the users object for the current set in "allSetUsers" state
        const index = allSetUsers.findIndex(
          (allSetUser: AllUsers) => allSetUser.deviceSetId === deviceSetId
        );

        // If it's not there, add it to it
        if (index === -1 && data) {
          const newData = {
            deviceSetId: deviceSetId,
            deviceSetUsers: data.allSetUsers.map((allSetUser) => ({
              userId: allSetUser.user.id,
              userRole: allSetUser.role,
              userName: allSetUser.user.details.name,
            })),
          };

          setAllSetUsers((prevAllSetUsers: AllUsers[]) => {
            const updatedAllSetUsers = [...prevAllSetUsers];
            updatedAllSetUsers.push(newData);
            return updatedAllSetUsers;
          });
        }
      },
    }
  );

  // Updating "currSetUsers" state
  useEffect(() => {
    const foundSetUser = allSetUsers.find(
      (obj: AllUsers) => obj.deviceSetId === deviceSetId
    );

    foundSetUser
      ? setCurrSetUsers(foundSetUser?.deviceSetUsers)
      : setCurrSetUsers([]);
  }, [allSetUsers, deviceSetId]);

  // Removing a user
  const handleRemoveClick = (userId: number, userName: string) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setConfirmModalOpen(true);
  };

  // Handle removing a user from set
  const [RemoveUserFromSet] = useMutation(RemoveUserFromSetDocument);

  const handleRemoveUser = async (userId: number | null) => {
    if (!userId) return;

    try {
      setIsDeleting(true);

      await RemoveUserFromSet({
        variables: {
          userId: userId,
          deviceSet: deviceSetId,
        },
      });

      // STATE UPDATE

      // Update Set Users
      function findAllChildSetIds(
        deviceSets: GeneratedDeviceSets[] | null,
        deviceSetId: number
      ) {
        const deviceSet = deviceSets?.find(
          (deviceSet) => deviceSet.id === deviceSetId
        );

        if (!deviceSet) return [];

        const childSetIds = deviceSet.deviceSets.map((set) => set.id);

        const nestedChildSetIds: number[] = childSetIds.flatMap(
          (childId: number) => findAllChildSetIds(deviceSets, childId)
        );

        return [...childSetIds, ...nestedChildSetIds];
      }

      const childSetIds = findAllChildSetIds(deviceSets, deviceSetId);

      setAllSetUsers((prevUsers: AllUsers[]) => {
        return prevUsers.map((user) => {
          const updatedUser = { ...user };

          if (
            user.deviceSetId === deviceSetId ||
            (childSetIds && childSetIds.includes(user.deviceSetId))
          ) {
            const updatedDeviceSetUsers = [...user.deviceSetUsers];

            updatedUser.deviceSetUsers = updatedDeviceSetUsers.filter(
              (deviceSetUser) => deviceSetUser.userId !== userId
            );
          }

          return updatedUser;
        });
      });

      setMessageInfo({
        message: "User removed from the set successfully.",
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: "Error removing user from the set: " + error.message,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message:
              "An unknown error occurred while removing the user from the set." +
              error,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      setIsDeleting(false);
      setSelectedUserId(null);
      setSelectedUserName(null);
      setConfirmModalOpen(false);
    }
  };

  // Handle removing a user from all sets
  const [RemoveUserAll] = useMutation(RemoveUserAllDocument);

  const handleRemoveUserAll = async (userId: number | null) => {
    if (!userId) return;

    try {
      setIsDeleting(true);

      await RemoveUserAll({
        variables: {
          user: userId,
        },
      });

      // Remove the user from all sets
      setAllSetUsers((prevUsers: AllUsers[]) => {
        return prevUsers.map((user) => {
          const updatedUser = { ...user };

          // Check if the user's deviceSetUsers array has the specified userId
          const updatedDeviceSetUsers = [...user.deviceSetUsers];

          const userIndex = updatedDeviceSetUsers.findIndex(
            (deviceSetUser) => deviceSetUser.userId === userId
          );

          if (userIndex !== -1) {
            updatedDeviceSetUsers.splice(userIndex, 1);
          }

          updatedUser.deviceSetUsers = updatedDeviceSetUsers;
          return updatedUser;
        });
      });

      // Remove user from all devices
      setAllDeviceUsers((prevUsers: AllDeviceUsers[]) => {
        return prevUsers.map((user) => {
          const updatedUser = { ...user };

          // Check if the user's deviceSetUsers array has the specified userId
          const updatedDeviceSetUsers = [...user.deviceUsers];

          const userIndex = updatedDeviceSetUsers.findIndex(
            (deviceSetUser) => deviceSetUser.userId === userId
          );

          if (userIndex !== -1) {
            updatedDeviceSetUsers.splice(userIndex, 1);
          }

          updatedUser.deviceUsers = updatedDeviceSetUsers;
          return updatedUser;
        });
      });

      setMessageInfo({
        message: "User removed from all sets successfully.",
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: "Error removing user from sets: " + error.message,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message:
              "An unknown error occurred while removing the user from the sets." +
              error,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      setIsDeleting(false);
      setSelectedUserId(null);
      setSelectedUserName(null);
      setConfirmModalOpen(false);
    }
  };

  return (
    <>
      {loadingUsers ? (
        <div className="full-width">
          <SpinnerLight />
        </div>
      ) : errorUsers ? (
        <p>An error occurred while fetching data.</p>
      ) : currSetUsers && currSetUsers.length === 0 ? (
        <p>No users found...</p>
      ) : (
        <ul className="is-flex is-flex-wrap-wrap gap-04">
          {currSetUsers &&
            currSetUsers.map(({ userId, userRole, userName }) => (
              <li className="mr-2" key={userId}>
                <div className="is-flex is-justify-content-space-between has-text-black bg-col-secondary border-radius-top border-radius-bottom">
                  <div className="pl-2 py-1">
                    <User
                      name={userName}
                      role={
                        userRole === DeviceRole.Administrator
                          ? userRole
                          : `Regular ${userRole}`
                      }
                      userId={userId}
                    />
                  </div>

                  {deviceSetRole === DeviceRole.Administrator && (
                    <div className="is-flex is-justify-content-center is-align-items-center">
                      <IconButton
                        className={`card-header-icon py-0 px-2`}
                        onClick={() => handleRemoveClick(userId, userName)}
                        iconColor={`has-text-grey`}
                        iconName="delete"
                      />
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      )}

      {/* Confirmation Modal */}
      <ModalComponent
        isOpen={isConfirmModalOpen}
        onRequestClose={() => {
          setSelectedUserId(null);
          setSelectedUserName(null);
          setConfirmModalOpen(false);
        }}
        actionButtons={modalActionButtons}
        isLoading={isDeleting}
        setIsLoading={setIsDeleting}
        className={`custom-modal`}
      >
        <p className="mb-4">Remove user "{selectedUserName}"?</p>
      </ModalComponent>
    </>
  );
};

export default SetUsers;
