import React, { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";

import { CreateSetProps } from "../helpers/interfacesTypesEnums";
import {
  DeviceSet as GeneratedDeviceSets,
  DeviceRole,
  AddSetDocument,
  AddSetToSetDocument,
} from "../generated/typesQueriesMutations";

import Input from "./elements/Input";
import Button from "./elements/Button";

const CreateSet = ({
  deviceSetId,
  deviceSets,
  setDeviceSets,
  setSubsets,
  setMessageInfo,
  setShowInput,
  setShowSettings,
  isSmall,
}: CreateSetProps) => {
  // States
  const [editedName, setEditedName] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState(false);

  // State Update
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditedName(event.target.value);

  const handleCloseEdit = () => {
    setShowInput(false);
    setEditedName("");
  };

  //   Mutation
  const [AddSet] = useMutation(AddSetDocument);
  const [AddSetToSet] = useMutation(AddSetToSetDocument);

  const handleAddSet = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsUpdating(true);

      const response = await AddSet({
        variables: {
          newDeviceSet: {
            name: editedName,
          },
        },
      });

      if (deviceSetId) {
        await AddSetToSet({
          variables: {
            setEntry: {
              parentDeviceSetId: deviceSetId,
              deviceSetId: response.data?.addSet.id,
            },
          },
        });
      }

      const newDeviceSet: GeneratedDeviceSets = {
        __typename: "DeviceSet",
        id: response.data?.addSet.id ?? 0,
        name: editedName,
        devices: [],
        deviceSets: [],
        myRole: response.data?.addSet.myRole || DeviceRole.User,
      };

      // State updates
      const parentSet = deviceSets?.findIndex((set) => set.id === deviceSetId);

      if (parentSet !== -1) {
        setDeviceSets((prevDeviceSets) => {
          const updatedDeviceSets = prevDeviceSets
            ? [...prevDeviceSets, newDeviceSet]
            : [newDeviceSet];

          if (deviceSetId) {
            const setIndex = updatedDeviceSets.findIndex(
              (set) => set.id === deviceSetId
            );

            if (setIndex !== -1) {
              updatedDeviceSets[setIndex].deviceSets.push(newDeviceSet);
            }
          }

          return updatedDeviceSets;
        });
      } else {
        if (!setSubsets || !deviceSetId) return;

        setSubsets((prevSubsets) => {
          const updatedSubsets = prevSubsets?.slice();

          const setIndex = updatedSubsets?.findIndex(
            (set) => set.id === deviceSetId
          );

          if (setIndex !== undefined && setIndex !== -1 && updatedSubsets) {
            updatedSubsets[setIndex].deviceSets.push(newDeviceSet);
          }

          return updatedSubsets || [];
        });

        setDeviceSets((prevDeviceSets) =>
          prevDeviceSets ? [...prevDeviceSets, newDeviceSet] : [newDeviceSet]
        );
      }

      setShowInput(false);
      setEditedName("");

      setMessageInfo({
        message: "Set created successfully.",
        messageType: "is-success",
      });
    } catch (error) {
      error instanceof ApolloError
        ? setMessageInfo({
            message: "Error adding set: " + error.message,
            messageType: "is-danger",
          })
        : setMessageInfo({
            message: "An unknown error occurred while adding the set." + error,
            messageType: "is-danger",
          });

      console.error(error);
    } finally {
      setIsUpdating(false);

      if (setShowSettings) setShowSettings(false);
    }
  };

  return (
    <div>
      <form className="field has-addons" onSubmit={handleAddSet}>
        <div className="control">
          <Input
            type="text"
            name="set"
            placeholder="Enter subset's name"
            value={editedName}
            onChange={handleNameChange}
            isSmall={isSmall}
          />
        </div>
        <div className="control">
          <Button
            className={`button is-success ${isSmall && "is-small"}`}
            disabled={editedName === ""}
            isLoading={isUpdating}
            text={"Add"}
          />
        </div>
        <div className="control">
          <Button
            className={`button border-radius ${isSmall && "is-small"}`}
            onClick={() => handleCloseEdit()}
            text={"Close"}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateSet;
