import React from "react";
import { ButtonProps } from "../../helpers/interfacesTypesEnums";

function Button({
  onClick,
  className,
  text,
  isLoading,
  disabled,
  children,
}: ButtonProps) {
  return (
    <button
      className={`${className} ${isLoading ? "is-loading" : ""}`}
      onClick={onClick}
      disabled={disabled}
      aria-label={text}
    >
      {children} {text}
    </button>
  );
}

export default Button;
